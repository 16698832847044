import { Rpc } from '@/models/Rpc';
import { Meeting } from '@/models/Meeting';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Damage } from '@/models/Damage';
import { Panel } from '@/views/Reports/Report';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<AdditionalRequestDialog>({})
export default class AdditionalRequestDialog extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  @Prop()
  protected meeting!: Meeting;

  @Prop()
  protected damages!: Damage[];
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */
  protected isLoading = false;

  protected isSucceeded = false;

  protected panels: Panel[] | null = [];

  protected payload: AdditionalRequestPayload = {};
  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */
  protected async mounted(): Promise<void> {

  }
  // #endregion

  // #region Class methods
  protected close() {
    this.$emit('input', false);
  }

  protected getDamageName(damage: Damage, index: number) {
    const defaultName = `Schade ${index + 1}`;
    if (! damage || ! damage.name) {
      return defaultName;
    }

    const name = damage.name.replace(/\s/g, '').length ? damage.name : defaultName;
    const revised = damage.answers && damage.answers.MarkerenAlsHerzien === 'Ja' ? '(herzien)' : '';

    return `${index + 1} ${name} ${revised}`;
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async handleSubmit() {
    const payload = {
      meeting: this.meeting.id,
      is_notifiable: true,
      feedback: this.payload?.additional_request || '',
      damages: this.damages
        .map((damage: ModifiedDamage) => ({
          id: damage.uuid,
          feedback: damage.additional_request || '',
        })),
    };

    this.isLoading = true;

    const feedback = await new Rpc()
      .execute('applicant:feedback', payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });

    if (feedback) {
      this.isSucceeded = true;
      this.$emit('feedbackSubmitted', feedback);
      setTimeout(() => {
        this.close();
      }, 5000);
    }
  }
  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}

interface AdditionalRequestPayload {
  additional_request?: string;
}

interface ModifiedDamage extends Damage {
  additional_request?: string;
}
