import { Rpc } from '@/models/Rpc';
import { Media } from '@/models/Media';
import { Meeting } from '@/models/Meeting';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Damage } from '@/models/Damage';
import { indexOf } from 'lodash';
import { AttachImageTarget } from '@/views/ChatRoom/ChatRoom';

@Component<AddImageDialog>({})
export default class AddImageDialog extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  @Prop({ default: false })
  protected isLeader!: boolean;

  @Prop({ default: false })
  protected isDisabled!: boolean;

  @Prop()
  protected meeting!: Meeting;

  @Prop()
  protected image!: Media;

  @Prop()
  protected damages!: Damage[];

  @Prop()
  protected defaultTarget!: AttachImageTarget;

  @Prop()
  protected defaultTargetValue!: string | null;
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */
  protected isLoading = false;

  protected selectedDamage: string | null = null;

  protected types: {[key: string]: string}[] = [
    {
      name: "Aanzichtfoto's",
      value: 'report',
    },
    {
      name: 'Schades',
      value: 'damage',
    },
  ];

  protected selectedType: AttachImageTarget | string = '';
  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */
  protected async mounted(): Promise<void> {
    this.selectedType = this.image.meta_meeting?.target || this.defaultTarget;
    this.selectedDamage = this.image.meta_meeting?.damage || this.defaultTargetValue;
  }
  // #endregion

  // #region Class methods
  protected close() {
    this.$emit('input', false);
  }

  protected getParsedDamageName(damage: Damage) {
    if (! damage) { return ''; }

    return damage.name && damage.name.length > 4 ? `${indexOf(this.damages, damage) + 1}. ${damage.name}` : `${indexOf(this.damages, damage) + 1}. Nieuwe schade ${indexOf(this.damages, damage) + 1}`;
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async handleSubmit() {
    const payload: {[key: string]: string} = {
      target: this.selectedType || '',
      meeting: this.meeting?.id || '',
      media: this.image.uuid || '',
    };

    if (this.selectedType === 'report') {
      payload.report = this.meeting?.event?.report?.uuid || '';
    }

    if (this.selectedType === 'damage') {
      payload.damage = this.selectedDamage || '';
    }

    const image = await new Rpc()
      .execute('meeting:media', payload);

    if (image) {
      this.$emit('imageAttached', {
        image: this.image.uuid,
        damage: this.selectedDamage,
        target: this.selectedType,
      });
      this.close();
    }
  }
  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */
  protected get hasSelectedDamageType() {
    return this.selectedType === 'damage';
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
