import { Options } from '@/components/mi-dialog/MiDialog';
import { Rpc } from '@/models/Rpc';
import { Damage } from '@/models/DamageNew';
import { Question } from '@/models/Question';
import { Report } from '@/models/Report';
import { Avatar } from '@/support/Avatar';
import ErrorHandler from '@/support/ErrorHandler';
import { addClass, removeClass } from '@/support/Html';
import { styles } from '@/support/Style';
import { AxiosError } from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import { AudioVideoObserver, ConsoleLogger, DefaultDeviceController, DefaultMeetingSession, DeviceChangeObserver, LogLevel, MeetingSessionConfiguration, VideoTileState } from 'amazon-chime-sdk-js';
import { ChecklistItem, ChecklistKey, ChecklistStatus, Meeting, MeetingIncludes, meetingStatusColor, meetingStatusLabels, Status, Submission, SubmissionDamage } from '@/models/Meeting';
import { Participant } from '@/models/Participant';
import { Media } from '@/models/Media';
import AddImageDialog from '@/views/ChatRoom/AddImageDialog/AddImageDialog.vue';
import { sortBy, isEmpty, cloneDeep } from 'lodash';
import { User, UserRole } from '@/models/User';
import AdditionalRequestDialog from '@/views/ChatRoom/AdditionalRequestDialog/AdditionalRequestDialog.vue';
import DuplicateDamageDialog from '@/components/dialog/DuplicateDamageDialog/DuplicateDamageDialog.vue';
import { formatDate } from '@/support/String';
import { isAcceptance, isProduction } from '@/support/ApplicationMode';

@Component<ChatRoom>({
  components: {
    AddImageDialog,
    AdditionalRequestDialog,
    DuplicateDamageDialog,
  },
})
export default class ChatRoom extends Vue {
  // #region @Props
  // #endregion

  // #region @Refs
  // #endregion

  // #region Class properties
  protected isLoading = true;

  protected isCreatingDamage = false;

  protected isDisplayingSnackbar = false;

  protected isDisplayingSidePanel = true;

  protected isMeetingEndedForIMG = false;

  protected isSessionStarted = false;

  protected isLoadingQuestions = true;

  protected isDisplayingSettingsDialog = false;

  protected isDisplayingAddImageDialog = false;

  protected isDisplayingPermissionDialog = false;

  protected isEndingSession = false;

  protected isEndingSubmission = false;

  protected isMakingPhoneRing = false;

  protected isDisplayingAdditionalRequestDialog = false;

  protected messageImageAddedPayload: MessageImageAddedPayload | null = null;

  protected meeting: Meeting | null = null;

  protected participants: Participant[] | null = null;

  protected report!: Report;

  protected damages: Damage[] = [];

  protected activeDamage: Damage | null = null;

  protected activeDamageIndex: number | null = null;

  protected damageQuestions: Question[] = [];

  protected showDrawer = false;

  protected images: Media[] = [];

  protected selectedImage: Media | null = null;

  protected activeTab = 'report';

  protected audioInputDevices: MediaDeviceInfo[] = [];

  protected selectedAudioInputDevice: MediaDeviceInfo | null = null;

  protected audioOutputDevices: MediaDeviceInfo[] = [];

  protected selectedAudioOutputDevice: MediaDeviceInfo | null = null;

  protected videoInputDevices: MediaDeviceInfo[] = [];

  protected selectedVideoInputDevice: MediaDeviceInfo | null = null;

  protected meetingSession: DefaultMeetingSession | null = null;

  protected hasPermissionAudio = false;

  protected hasPermissionVideo = false;

  protected videoElements: NodeListOf<any> | null = null;

  protected tileStates: VideoTileState[] = [];

  protected activeTileId: null | number = null;

  protected isMuted = false;

  protected isShowingCamera = true;

  protected allAttendees: Attendee[] = [];

  protected allAttendeesMeta: {[id: string]: AttendeeMeta} = {};

  protected icons = {
    web: 'public',
    android: 'adb',
    ios: 'mdi-apple',
  };

  protected activeBrowser = '';

  protected snackbarTitle = '';

  protected snackbarDescription = '';

  protected snackbarImage: Media | null = null;

  protected applicantUpload: UploadValidationResponse | null = null;

  protected applicantUploadInterval: NodeJS.Timer | null = null;

  protected isDuplicatingDamage = false;
  // #endregion

  // #region Lifecycle Hooks / Init
  protected mounted() {
    if (this.isChrome) {
      this.activeBrowser = 'chrome';
    }
    if (this.isFirefox) {
      this.activeBrowser = 'firefox';
    }

    const pageContainer = document.querySelectorAll('.pageContainer')[0];
    addClass(pageContainer, 'pageContainer--fullscreen');
    this.$store.dispatch('setDisplayingNavigation', false);
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    this.isLoading = true;
    await this.fetchMeeting();
    await this.fetchParticipants();
    await this.fetchDamages();
    this.setActiveDamage(this.damages[0], 0);

    if (this.isSessionEnded) {
      if (this.isLeader) {
        this.setIntervalApplicantUpload();
      } else {
        this.isMeetingEndedForIMG = true;
      }
    }

    this.isLoading = false;
  }

  protected beforeDestroy() {
    this.leaveSession();
    const pageContainer = document.querySelectorAll('.pageContainer')[0];
    removeClass(pageContainer, 'pageContainer--fullscreen');
    this.$store.dispatch('setDisplayingNavigation', true);
    clearInterval(this.applicantUploadInterval as NodeJS.Timer);
  }
  // #endregion

  // #region Class methods
  protected navigateToReport() {
    const pageContainer = document.querySelectorAll('.pageContainer')[0];
    removeClass(pageContainer, 'pageContainer--fullscreen');
    this.$store.dispatch('setDisplayingNavigation', true);
    this.$router.push(`/reports/${this.report?.uuid ? this.report.uuid : ''}/planning`);
  }

  protected checklistIcon(key: ChecklistKey): string {
    switch (key) {
      case 'permission':
        return 'mdi-shield-account';
      case 'microphone':
        return 'mdi-microphone';
      case 'camera':
        return 'mdi-video';
      case 'notifications':
        return 'mdi-bell';
      case 'storage':
      default:
        return 'mdi-database';
    }
  }

  protected checklistStatusIcon(status: ChecklistStatus): string {
    switch (status) {
      case 'authorized':
        return 'mdi-check';
      case 'denied':
      case 'restricted':
        return 'mdi-close ';
      case 'undetermined':
      default:
        return 'mdi-exclamation';
    }
  }

  protected async setActiveDamage(damage: Damage, index: number): Promise<void> {
    this.showDrawer = false;
    this.activeDamageIndex = index;
    this.activeDamage = damage;
    this.getQuestions();
  }

  protected damageAnswerSaved(damage: Damage) {
    const index = this.damages.findIndex((currentDamage: Damage) => currentDamage.uuid === damage.uuid);

    if (index < 0) {
      return;
    }

    this.damages[index].name = damage.name;

    if (! this.activeDamage) {
      return;
    }

    this.activeDamage.answers = damage.answers;
  }

  protected findDefaultDevice(devices: MediaDeviceInfo[]): MediaDeviceInfo | null {
    const foundDevice = devices.find((device: MediaDeviceInfo) => device.deviceId === 'default');

    return foundDevice || devices[0];
  }

  protected mute(): void {
    if (! this.meetingSession) { return; }
    this.meetingSession.audioVideo.realtimeMuteLocalAudio();
    this.isMuted = true;
  }

  protected unmute(): void {
    if (! this.meetingSession) { return; }
    const unmuted = this.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
    if (unmuted) {
      console.log('Other attendees can hear your audio');
      this.isMuted = false;
    } else {
      // See the realtimeSetCanUnmuteLocalAudio use case below.
      console.log('You cannot unmute yourself');
      this.isMuted = true;
    }
  }

  protected fetchAttendeeMeta(attendee: any, pausedState = false): void {
    if (! this.meetingSession) { return; }
    this.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(
      attendee.presentAttendeeId,
      (attendeeId, volume, muted, signalStrength, externalUserId) => {
        const meta: AttendeeMeta = {
          volume: volume || 0, // a fraction between 0 and 1
          muted: muted || false, // a boolean
          signalStrength: signalStrength || 0, // 0 (no signal), 0.5 (weak), 1 (strong)
          isPaused: pausedState,
        };

        if (attendee.presentAttendeeId === attendeeId) {
          this.$set(this.allAttendeesMeta, `${externalUserId}`, meta);
        }
      },
    );
  }

  protected getTileIdIndex(tileId: number): number {
    const index = this.externalTiles.findIndex((externalTile) => externalTile.tileId === tileId);

    return index;
  }

  protected findParticipants(userId: string): Participant | null {
    const user = this.participants?.find((participant) => participant.id === userId);

    return user || null;
  }

  protected onClickOpenImageDialog(media: Media): void {
    this.isDisplayingSnackbar = false;
    this.selectedImage = media;
    this.isDisplayingAddImageDialog = true;
  }

  protected onClickOpenDeleteImageDialog(media: Media): void {
    const dialog = {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze afbeelding wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ja, verwijder',
          action: async () => {
            if (! media) {
              return;
            }
            await this.deleteMedia(media);
            this.$set(this, 'images', this.images.filter((image) => image.uuid !== media.uuid));
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
        },
      },
    };

    this.$store.dispatch('openDialog', dialog);
  }

  protected async onImageDragEnd(media: Media[]): Promise<void> {
    // Directly update the order of the array for UI experience (direct update of order without flickering)
    const originalOrder = cloneDeep(this.images);

    this.images = media;

    // Perform the update
    const success = await this.updateMediaOrder(media);

    // If update failed revert the order
    if (! success) {
      this.images = originalOrder;
    }
  }

  protected sortCreatedAt(a: any, b: any): number {
    let comparison = 0;

    if (a.created_at === undefined || b.created_at === undefined) {
      return 0;
    }

    if (a.created_at > b.created_at) {
      comparison = 1;
    } else if (a.created_at < b.created_at) {
      comparison = - 1;
    }
    return comparison;
  }

  protected handleRealtimeMessage(event: MessageEventType, data: ChimeMessagePayload): void {
    switch (event) {
      case MessageEventType.IMAGE_ADDED:
        this.onRealtimeImageAdded(data as MessageImageAddedPayload);
        break;
      case MessageEventType.IMAGE_ATTACHED:
        this.onRealtimeImageAttached(data as MessageImageAttachedPayload);
        break;
      case MessageEventType.MEETING_ENDED:
        if (this.$store.state.isServiceOrganization && this.meeting) {
          this.isMeetingEndedForIMG = true;
          const message = data as MeetingEndingPayload;
          this.$set(this.meeting, 'status', message.status);
        }
        break;
      case MessageEventType.VIDEO_PAUSED:
        this.onRealTimeVideoPaused(data, true);
        break;
      case MessageEventType.VIDEO_UNPAUSED:
        this.onRealTimeVideoPaused(data);
        break;
      default:
        break;
    }
  }

  protected onRealTimeVideoPaused(data: ChimeMessagePayload, pausedState = false): void {
    this.allAttendees.forEach((attendee) => {
      const isPaused = attendee.presentAttendeeId === data.senderAttendeeId && pausedState;
      this.fetchAttendeeMeta(attendee, isPaused);
    });
  }

  protected onModalImageAttached(message: MessageImageAttachedPayload): void {
    this.broadcastImageAttached(message.image, message.target, message.damage);
    this.fetchMeeting();
  }

  protected broadcastImageAttached(image: string, target: AttachImageTarget, damage?: string): void {
    const payload: {[key: string]: string} = {
      image: image || '',
      target,
    };

    if (target === 'damage' && damage) {
      payload.damage = damage;
    }

    this.broadcast(MessageEventType.IMAGE_ATTACHED, payload);
  }

  protected broadcast(event: MessageEventType, data: object = {}): void {
    const payload: {[key: string]: string} = {
      ...{ event },
      ...data,
    };

    this.meetingSession?.audioVideo.realtimeSendDataMessage('default', JSON.stringify(payload), 10000);
  }

  protected leaveSession(): void {
    if (! this.meetingSession) { return; }
    this.meetingSession.audioVideo.stop();
    this.isSessionStarted = false;
    this.isMuted = false;
    this.isDisplayingSidePanel = false;
    this.allAttendees = [];
  }

  protected endSession(): void {
    this.$store.dispatch('openDialog', this.dialogOptionsEndSession);
  }

  protected endSessionPermanent(): void {
    this.$store.dispatch('openDialog', this.dialogOptionsEndSessionPermanent);
  }

  protected setIntervalApplicantUpload(): void {
    this.fetchApplicantUpload();
    this.applicantUploadInterval = setInterval(async () => {
      this.fetchApplicantUpload();
    }, 5000);
  }

  protected imageUploadedStatusIconClass(image: Media) {
    if (! this.isSessionEnded) {
      return 'primary';
    }

    if (this.imageIsStatus(image, 'completed')) {
      return 'completed';
    }

    if (this.imageIsStatus(image, 'uploaded')) {
      return 'primary';
    }

    if (this.imageIsStatus(image, 'missing')) {
      return 'missing';
    }
  }

  protected imageUploadedStatusIcon(image: Media) {
    if (! this.isSessionEnded) {
      return 'link';
    }

    if (this.imageIsStatus(image, 'completed')) {
      return 'done';
    }

    if (this.imageIsStatus(image, 'uploaded')) {
      return 'cloud';
    }

    if (this.imageIsStatus(image, 'missing')) {
      return 'hourglass_empty';
    }

    return '';
  }

  protected imageIsStatus(image: Media, status: UploadValidationStatus): boolean {
    if (! this.applicantUpload || ! image) { return false; }
    return this.applicantUpload[status].some((token) => image.meta_meeting?.token === token);
  }

  protected additionallyRequest(): void {
    this.isDisplayingAdditionalRequestDialog = true;
  }

  protected navigateToMsrEditor(): void {
    if (! this.meeting?.submission?.id) { return; }
    this.$router.push(`/mijn-schaderegelen/${this.meeting?.submission.id}`);
  }

  protected feedbackSubmitted(submission: Submission): void {
    if (! this.meeting) { return; }
    this.$set(this.meeting, 'submission', submission);
  }

  protected getSubmissionDamage(damage: Damage): SubmissionDamage | null {
    if (! this.meeting?.submission?.submission_damages) { return null; }
    const submissionDamage = this.meeting?.submission?.submission_damages.find((submissionDamage) => submissionDamage.id === damage.uuid);

    return submissionDamage || null;
  }

  protected damageDuplicated(damage: Damage): void {
    if (! this.activeDamage || ! damage) {
      return;
    }

    const index = this.damages.findIndex((damage) => damage.uuid === this.activeDamage?.uuid);
    this.damages.splice(index + 1, 0, damage);
  }
  // #endregion

  // #region Async methods
  protected async fetchMeeting(): Promise<void> {
    if (! this.$route.params.id) {
      return;
    }

    this.meeting = await new Meeting()
      .include([
        MeetingIncludes.EVENT,
        MeetingIncludes.PARTICIPANTS,
        MeetingIncludes.REPORT,
        MeetingIncludes.RISK_PROFILE,
        MeetingIncludes.MEDIA,
        MeetingIncludes.META_MEETING,
        MeetingIncludes.APPLICANT,
        MeetingIncludes.CHECKLIST,
        MeetingIncludes.SUBMISSION,
        MeetingIncludes.SUBMISSION_DAMAGES,
        MeetingIncludes.ANSWERS,
        MeetingIncludes.DATA,
      ])
      .find(this.$route.params.id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.images = this.meeting?.media?.filter((media) => media.type === 'preview') || [];
    this.images = sortBy(this.images, 'sort_order');
    this.report = new Report(this.meeting?.event?.report);
  }

  protected async getQuestions() {
    await this.$nextTick(async () => {
      this.isLoadingQuestions = true;
      await this.fetchDamageQuestions();
      this.isLoadingQuestions = false;
    });
  }

  protected async fetchParticipants(): Promise<void> {
    if (! this.meeting) {
      return;
    }

    const payload: {[key: string]: any} = {
      signature: 'meeting:participants',
      body: {
        meeting: this.meeting.id,
      },
    };

    this.participants = await new Rpc()
      .rpcPost(payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });
  }

  protected async fetchMeetingResponse(): Promise<MeetingResponse | null> {
    if (! this.meeting) {
      return null;
    }

    const payload: {[key: string]: any} = {
      signature: 'meeting:attend',
      body: {
        device: 'web',
        meeting: this.meeting.id,
      },
    };

    return await new Rpc()
      .rpcPost(payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });
  }

  protected async fetchDamages(): Promise<void> {
    if (! this.report || ! this.report.uuid) { return; }

    this.damages = await new Damage()
      .refactor()
      .include(['validations', 'reject_reasons', 'answers', 'media'])
      .filter({ report: this.report.uuid })
      .limit(1000)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async fetchDamageQuestions(): Promise<void> {
    if (! this.report || ! this.report.uuid) { return; }

    const payload = {
      form_types: ['manager', 'tcmg', 'expert', 'pre_controller'],
      sections: this.report.isRegulier2021Report ? 121 : 51,
      report_type: this.report.type?.uuid,
      report: this.report.uuid,
    };

    this.damageQuestions = await new Question()
      .filter(payload)
      .limit(300)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async createDamage(): Promise<void> {
    if (! this.report || ! this.report.uuid) { return; }

    this.isCreatingDamage = true;
    const newDamage = await new Damage()
      .create({ report: this.report.uuid })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    await this.fetchDamages();
    const index = this.damages.findIndex((damage) => damage.uuid === newDamage.uuid);

    this.setActiveDamage(newDamage, index);
    this.isCreatingDamage = false;
  }

  protected async attachImage(imageId: string, target?: AttachImageTarget, value?: string): Promise<void> {
    const payload: {[key: string]: string} = {
      target: target || this.defaultTarget,
      meeting: this.meeting?.id || '',
      media: imageId,
    };

    if (payload.target === 'report') {
      payload.report = this.meeting?.event?.report?.uuid || '';
    }

    if (payload.target === 'damage') {
      console.log({
        value,
        default: this.defaultTargetValue,
      });
      payload.damage = value || this.defaultTargetValue;
    }

    const media: Media = new Media(
      await new Rpc().execute('meeting:media', payload),
    );

    const foundMedia = this.images.find((findMedia) => findMedia.uuid === media.uuid);

    if (foundMedia) {
      foundMedia.meta_meeting = media.meta_meeting;
    } else {
      this.images.unshift(media);
    }
  }

  protected async initializeAmazonChime(): Promise<void> {
    const logger = new ConsoleLogger('MyLogger', LogLevel.INFO);
    const deviceController = new DefaultDeviceController(logger);
    const meetingReponse = await this.fetchMeetingResponse();

    if (! meetingReponse) { return; }

    const configuration = new MeetingSessionConfiguration(meetingReponse.meeting, meetingReponse.attendee);

    // CREATE MEETING
    this.meetingSession = new DefaultMeetingSession(
      configuration,
      logger,
      deviceController,
    );

    if (! this.meetingSession) {
      return;
    }

    // GET DEVICES
    const audioInputDevices = await this.meetingSession.audioVideo.listAudioInputDevices();
    const audioOutputDevices = await this.meetingSession.audioVideo.listAudioOutputDevices();
    const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();

    const allDevices = [...audioInputDevices, ...audioOutputDevices, ...videoInputDevices];
    this.hasPermissionAudio = await this.getPermission('audio');
    this.hasPermissionVideo = await this.getPermission('video');

    if (this.hasPermissionAudio) {
      this.audioInputDevices = allDevices.filter((device) => device.kind === 'audioinput');
      this.audioOutputDevices = allDevices.filter((device) => device.kind === 'audiooutput');

      if (this.audioInputDevices) {
        this.selectedAudioInputDevice = this.findDefaultDevice(this.audioInputDevices);
        if (this.selectedAudioInputDevice?.deviceId) {
          await this.meetingSession.audioVideo.chooseAudioInputDevice(this.selectedAudioInputDevice.deviceId);
        }
      }

      if (this.audioOutputDevices) {
        this.selectedAudioOutputDevice = this.findDefaultDevice(this.audioOutputDevices);
        if (this.selectedAudioOutputDevice?.deviceId) {
          await this.meetingSession.audioVideo.chooseAudioOutputDevice(this.selectedAudioOutputDevice.deviceId);
        }
      }
    }

    if (this.hasPermissionVideo) {
      this.videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();

      if (this.videoInputDevices) {
        this.selectedVideoInputDevice = this.findDefaultDevice(this.videoInputDevices);
        if (this.selectedVideoInputDevice?.deviceId) {
          await this.meetingSession.audioVideo.chooseVideoInputDevice(this.selectedVideoInputDevice.deviceId);
        }
      }
    }

    this.isSessionStarted = true;

    if (! this.hasPermissionAudio || ! this.hasPermissionVideo) {
      this.isDisplayingPermissionDialog = true;
    }

    await this.$nextTick();

    // AUDIO
    const audioElement = document.querySelectorAll('.attendee-audio-element') as NodeListOf<HTMLAudioElement>;
    this.meetingSession.audioVideo.bindAudioElement(audioElement[0]);

    // MESSAGES
    this.meetingSession?.audioVideo.realtimeSubscribeToReceiveDataMessage('default', (message) => {
      const data = { ...message.json() as ChimeMessagePayload,
        ...{
          timestampMs: message.timestampMs,
          topic: message.topic,
          senderAttendeeId: message.senderAttendeeId,
          senderExternalUserId: message.senderExternalUserId,
        } };
      this.handleRealtimeMessage(data.event, data);
    });

    // START
    this.meetingSession.audioVideo.addDeviceChangeObserver(this.deviceChangeObserver);
    this.meetingSession.audioVideo.addObserver(this.audioVideoObserver);
    this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(this.attendeePresenceObserver);
    this.meetingSession.audioVideo.startLocalVideoTile();
    this.meetingSession.audioVideo.start();

    this.isDisplayingSidePanel = true;
  }

  protected async onRealtimeImageAdded(message: MessageImageAddedPayload): Promise<void> {
    const media: Media = await this.getImage(message.image);

    const title = 'Foto toegevoegd';

    if (media) {
      this.images.unshift(media);
    }

    if (this.isLeader) {
      await this.attachImage(message.image, this.defaultTarget, this.defaultTargetValue);
      this.broadcastImageAttached(media.uuid, this.defaultTarget, this.defaultTargetValue);
    }

    this.showSnackbar(media, title);
  }

  protected async getImage(image: string): Promise<any> {
    const foundImage = this.images.find((internalImage) => internalImage.uuid === image);

    if (foundImage) {
      return foundImage;
    }

    return await new Media()
      .include('meta_meeting')
      .find(image)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async onRealtimeImageAttached(message: MessageImageAttachedPayload): Promise<void> {
    const image = await this.getImage(message.image);

    if (image.meta_meeting) {
      image.meta_meeting.damage = message.damage || image.meta_meeting.damage;
      image.meta_meeting.target = message.target;
    }

    const title = 'Foto gekoppeld';
    let description;

    if (message.target === 'damage') {
      const damageIndex = this.damages.findIndex((damage) => damage.uuid === message?.damage) || 0;
      const damage = this.damages.find((damage) => damage.uuid === message?.damage);

      const damageNumber = damageIndex + 1;
      const damageName = damage && damage.name?.replace(/\s/g, '').length
        ? damage.name
        : `Nieuwe schade ${damageNumber}`;
      description = this.isLeader ? `${damageNumber}. ${damageName}` : undefined;
    }

    if (message.target === 'report') {
      description = 'Aanzichtfoto';
    }

    // FIXME: this function does nothing?
    // this.showSnackbar(image, title, description);
  }

  protected async showSnackbar(media: Media, title: string, description?: string): Promise<void> {
    this.isDisplayingSnackbar = false;
    this.snackbarImage = media;
    this.snackbarTitle = title;
    this.snackbarDescription = description || '';

    this.$nextTick(() => {
      this.isDisplayingSnackbar = true;
    });
  }

  protected async acquireElement(tileState: VideoTileState) {
    await this.$nextTick();
    const elements = document.querySelectorAll('.attendee-video-element') as NodeListOf<HTMLVideoElement>;

    if (tileState.localTile) {
      const videoElement = document.querySelectorAll('.internal-attendee-video-element') as NodeListOf<HTMLVideoElement>;
      return videoElement[0];
    }

    let foundElement;
    elements.forEach((element) => {
      if (element.id === `attendee-video-element-${tileState.tileId}`) {
        foundElement = element;
      }
    });

    return foundElement || null;
  }

  protected async saveSettings(): Promise<void> {
    await this.onChangeAudioInputDevices();
    await this.onChangeAudioOutputDevice();
    await this.onChangeVideoInputDevices();

    this.isDisplayingSettingsDialog = false;
  }

  protected async onChangeAudioInputDevices(): Promise<void> {
    if (! this.meetingSession || ! this.selectedAudioInputDevice) { return; }
    await this.meetingSession.audioVideo.chooseAudioInputDevice(this.selectedAudioInputDevice.deviceId);
  }

  protected async onChangeAudioOutputDevice(): Promise<void> {
    if (! this.meetingSession || ! this.selectedAudioOutputDevice) { return; }
    await this.meetingSession.audioVideo.chooseAudioOutputDevice(this.selectedAudioOutputDevice.deviceId);
  }

  protected async onChangeVideoInputDevices(): Promise<void> {
    if (! this.meetingSession || ! this.selectedVideoInputDevice) { return; }
    await this.meetingSession.audioVideo.chooseVideoInputDevice(this.selectedVideoInputDevice.deviceId);
  }

  protected async getPermission(value: string): Promise<boolean> {
    return await navigator.mediaDevices
      .getUserMedia({ [value]: true })
      .then(() => true).catch((error) => false);
  }

  protected async fetchApplicantUpload(): Promise<void> {
    if (! this.meeting) { return; }

    const applicantUpload: UploadValidationResponse = await new Rpc()
      .execute('meeting:upload-status', {
        meeting: this.meeting.id,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (applicantUpload.progress === 100) {
      clearInterval(this.applicantUploadInterval as NodeJS.Timer);
      this.$set(this.meeting, 'status', applicantUpload.status);
    }

    this.applicantUpload = applicantUpload;
  }

  protected async endMeeting(): Promise<void> {
    if (! this.meeting) { return; }

    const payload = {
      signature: 'meeting:end',
      body: {
        meeting: this.meeting.id,
      },
    };

    try {
      await new Rpc()
        .rpcPost(payload);
    } catch (error) {
      ErrorHandler.network(error);
    }
  }

  protected async deleteMedia(media: Media): Promise<void> {
    const payload = {
      signature: 'meeting:media:delete',
      body: {
        media: media.uuid,
      },
    };

    try {
      await new Rpc().rpcPost(payload);
      if (media?.meta_meeting?.token) {
        this.broadcast(MessageEventType.IMAGE_DELETED, { image: media.meta_meeting.token });
      }
    } catch (error) {
      ErrorHandler.network(error);
    }
  }

  get isProduction(): boolean {
    return isProduction();
  }

  protected async updateMediaOrder(media: Media[]): Promise<boolean> {
    const payload = {
      signature: 'media:order',
      body: {
        media: media.map((media) => media.uuid),
      },
    };

    try {
      await new Rpc()
        .rpcPost(payload);

      return true;
    } catch (error) {
      ErrorHandler.network(error);
      return false;
    }
  }

  protected async updateMeetingStatus(status: string): Promise<any> {
    if (! this.meeting) { return; }

    return await this.meeting
      .update({
        status,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async onClickMakePhoneRing(): Promise<void> {
    if (! this.meeting) { return; }

    this.isMakingPhoneRing = true;
    const payload = {
      signature: 'applicant:call',
      body: {
        meeting: this.meeting.id,
      },
    };

    const response = await new Rpc()
      .rpcPost(payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isMakingPhoneRing = false;
        return null;
      });

    if (response) {
      this.$store.dispatch('openDialog', this.dialogOptionsSuccessMakePhoneRing);

      setTimeout(() => {
        this.isMakingPhoneRing = false;
      }, 60000);
    }
  }
  // #endregion

  // #region Getters & Setters
  protected get userAvatar(): string {
    if (this.$store.state.Auth && this.$store.state.Auth.contact && this.$store.state.Auth.contact.avatar && ! this.$store.state.Auth.contact.avatar.includes('gravatar')) {
      return this.$store.state.Auth.contact.avatar;
    }

    return new Avatar(this.$store.state.Auth.name, { color: styles.white, background: styles.primary }).toDataUrl();
  }

  // Observers
  protected get audioVideoObserver(): AudioVideoObserver {
    return {
      audioVideoDidStart: () => {
        console.log('Started');
      },
      audioVideoDidStop: (sessionStatus) => {
        // See the "Stopping a session" section for details.
        console.log('Stopped with a session status code: ', sessionStatus.statusCode());
      },
      audioVideoDidStartConnecting: (reconnecting) => {
        if (reconnecting) {
          // e.g. the WiFi connection is dropped.
          console.log('Attempting to reconnect');
        }
      },
      videoTileDidUpdate: async (tileState) => {
        if (! this.meetingSession || ! tileState.tileId) {
          return;
        }

        if (! this.tileStates.some((internalTileState) => internalTileState.tileId === tileState.tileId) && tileState.boundExternalUserId) {
          this.tileStates.push(tileState);
        }

        const element = await this.acquireElement(tileState);

        if (element) {
          this.meetingSession.audioVideo.bindVideoElement(
            tileState.tileId,
            element,
          );
        }
      },
      videoTileWasRemoved: (tileId) => {
        this.tileStates = this.tileStates.filter((internalTileState) => internalTileState.tileId !== tileId);
      },
    };
  }

  protected get defaultTarget(): AttachImageTarget {
    return this.activeDamage ? 'damage' : 'report';
  }

  protected get defaultTargetValue(): string {
    if (this.defaultTarget === 'damage') {
      return this.activeDamage?.uuid || this.report.uuid;
    }

    return this.report.uuid;
  }

  protected get deviceChangeObserver(): DeviceChangeObserver {
    return {
      audioInputsChanged: (freshAudioInputDeviceList) => {
        // An array of MediaDeviceInfo objects
        freshAudioInputDeviceList?.forEach((mediaDeviceInfo) => {
          console.log(`Device ID: ${mediaDeviceInfo.deviceId} Microphone: ${mediaDeviceInfo.label}`);
        });
      },
      audioOutputsChanged: (freshAudioOutputDeviceList) => {
        console.log('Audio outputs updated: ', freshAudioOutputDeviceList);
        this.audioOutputDevices = freshAudioOutputDeviceList || [];
      },
      videoInputsChanged: (freshVideoInputDeviceList) => {
        console.log('Video inputs updated: ', freshVideoInputDeviceList);
        this.audioInputDevices = freshVideoInputDeviceList || [];
      },
    };
  }

  protected get attendeePresenceObserver() {
    return async (presentAttendeeId: string, present: boolean, externalUserId?: string) => {
      if (present) {
        const attendee = {
          presentAttendeeId,
          externalUserId: externalUserId || '',
        };

        if (! this.allAttendees.find((attendee) => attendee.presentAttendeeId === presentAttendeeId)) {
          this.allAttendees.push(attendee);
        }
      } else {
        const index = this.allAttendees.findIndex((attendee) => attendee.externalUserId === externalUserId);
        this.allAttendees.splice(index, 1);
      }

      await this.fetchParticipants();

      this.allAttendees.forEach((attendee) => {
        this.fetchAttendeeMeta(attendee);
      });
    };
  }

  //  create getter to get localtile
  protected get localTileState(): VideoTileState | undefined {
    return this.tileStates.find((tileState) => tileState.localTile);
  }

  //  create getter to external tiles object
  protected get externalTileStates() {
    const externalTileStates = this.tileStates.filter((tileState) => ! tileState.localTile);
    const object: {[key: number]: ModifedVideoTileState} = {};
    externalTileStates.forEach((tileState) => {
      if (! tileState.tileId) { return; }
      object[tileState.tileId] = tileState;
    });

    return object;
  }

  //  create getter to external non-active tiles array
  protected get externalTiles(): VideoTileState[] {
    const externalTileStates = this.tileStates.filter((tileState) => ! tileState.localTile);
    const filtered = externalTileStates.filter((external) => this.activeTileId !== external.tileId);
    return filtered;
  }

  protected get gridTemplateColumns(): 1 | 2 {
    if (this.activeTileId) {
      return 1;
    }

    return Object.keys(this.externalTileStates).length >= 2 ? 2 : 1;
  }

  protected get applicantPermissions(): ChecklistItem[] {
    // @DEBUG Simulate the app having not yet been opened by the applicant.
    return this.meeting?.applicant?.checklist?.items || [];
  }

  protected get applicantPermissionsUpdatedAt(): string {
    return formatDate(this.meeting?.applicant?.checklist?.updated_at || '', 'dd-LL-yyyy HH:MM');
  }

  protected get user(): User {
    return this.$store.state.Auth;
  }

  protected get isChrome(): boolean {
    return navigator.userAgent.indexOf('Chrome') !== - 1;
  }

  protected get isFirefox(): boolean {
    return navigator.userAgent.indexOf('Firefox') !== - 1;
  }

  protected get isLeader(): boolean {
    return ! this.$store.state.isServiceOrganization;
  }

  protected get reportImages(): Media[] {
    return this.images.filter((image) => image?.meta_meeting?.target === 'report');
  }

  protected get damageImages(): Media[] {
    return this.images.filter((image) => image?.meta_meeting?.target === 'damage' && image?.meta_meeting?.damage === this.activeDamage?.uuid);
  }

  protected get statusLabel(): string {
    return this.meeting?.status ? meetingStatusLabels[this.meeting.status] : '';
  }

  protected get statusColor(): string {
    return this.meeting?.status ? meetingStatusColor[this.meeting.status] : '';
  }

  protected get isSessionEnded(): boolean {
    return this.meeting?.status === 'processing' || this.isDisabled;
  }

  protected get isDisabled(): boolean {
    return this.meeting?.status === 'processed' || this.meeting?.status === 'synced';
  }

  protected get dialogOptionsEndSession(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze opname wilt beëindigen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ja, beëindig',
          action: async () => {
            if (! this.meeting) {
              return;
            }

            this.isEndingSession = true;

            await this.endMeeting();

            const meeting: Meeting = await this.updateMeetingStatus('processing');

            if (meeting) {
              this.broadcast(MessageEventType.MEETING_ENDED, { status: 'processing' });
              this.$set(this.meeting, 'status', meeting.status);
              this.leaveSession();
              this.setIntervalApplicantUpload();
              this.isDisplayingSidePanel = true;
              this.isEndingSession = false;
            }
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
        },
      },
    };
  }

  protected get dialogOptionsEndSessionPermanent(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze opname definitief wilt beëindigen?',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ja, beëindig',
          action: async () => {
            if (! this.meeting) {
              return;
            }

            this.isEndingSubmission = true;

            await this.endMeeting();

            const meeting: Meeting = await this.updateMeetingStatus('processed');
            this.$set(this.meeting, 'status', meeting.status);
            this.isEndingSubmission = false;
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
        },
      },
    };
  }

  protected get isDisplayingChatRoomContent(): boolean {
    if (this.isMeetingEndedForIMG) {
      return true;
    }

    return ! this.isSessionEnded;
  }

  protected get isDisplayingChatRoom() {
    if (this.isMeetingEndedForIMG) {
      return true;
    }

    return ! this.isSessionStarted;
  }

  protected get hasSubmission(): boolean {
    return ! isEmpty(this.meeting?.submission);
  }

  protected get chatRoomContentClasses() {
    return {
      'chat-room-content--white': this.isSessionStarted && this.isLeader,
    };
  }

  protected get chatRoomSidepanelClasses() {
    return {
      'chat-room-sidepanel--hidden': ! this.isDisplayingSidePanel && ! this.isSessionEnded,
      'chat-room-sidepanel--session_started': this.isSessionStarted,
      'chat-room-sidepanel--session_ended xs10': this.isSessionEnded && this.isLeader,
      'chat-room-sidepanel--hidden xs12': this.isSessionEnded && ! this.isLeader,
    };
  }

  protected get dialogOptionsSuccessMakePhoneRing(): Options {
    return {
      title: 'Succes!',
      text: 'Een ogenblik geduld alstublieft, de aanvrager wordt nu gebeld.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'ok',
        },
      },
    };
  }

  protected get hasMediaDeletionPermission(): boolean {
    if (this.isProduction) {
      return false;
    }

    return ! this.$store.state.isServiceOrganization && this.user.hasRole([
      UserRole.EXPERT,
      UserRole.CASE_MEDIATOR,
      UserRole.SERVICELOKET,
      UserRole.CASE_MEDIATOR,
      UserRole.PRE_CONTROLLER,
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.HELPDESK_TCMG,
      UserRole.PLANNING,
      UserRole.WERKVOORBEREIDING,
    ]);
  }

  protected get hasMediaReorderPermission(): boolean {
    if (this.isProduction) {
      return false;
    }

    return ! this.$store.state.isServiceOrganization && this.user.hasRole([
      UserRole.EXPERT,
      UserRole.CASE_MEDIATOR,
      UserRole.SERVICELOKET,
      UserRole.CASE_MEDIATOR,
      UserRole.PRE_CONTROLLER,
      UserRole.MANAGER,
      UserRole.ADMIN,
      UserRole.HELPDESK_TCMG,
      UserRole.PLANNING,
      UserRole.WERKVOORBEREIDING,
    ]);
  }

  // #endregion

  // #region @Watchers

  // #endregion
}

export interface MeetingRecord {
  title: string;
  meeting: {
      MeetingId: string;
      MediaPlacement: {
          AudioHostUrl: string;
          AudioFallbackUrl: string;
          ScreenDataUrl: string;
          ScreenSharingUrl: string;
          ScreenViewingUrl: string;
          SignalingUrl: string;
          TurnControlUrl: string;
      };
      MediaRegion: string;
  };
  attendee: {
      ExternalUserId: string;
      AttendeeId: string;
      JoinToken: string;
  }
}

interface ModifedVideoTileState extends VideoTileState {
  meta?: AttendeeMeta;
}

interface MeetingResponse {
  title: string;
  attendee: {
    ExternalUserId: string;
    AttendeeId: string;
    JoinToken: string;
  },
  meeting: {
    MediaPlacement: {
      AudioFallbackUrl: string;
      AudioHostUrl: string;
      ScreenDataUrl: string;
      ScreenSharingUrl: string;
      ScreenViewingUrl: string;
      SignalingUrl: string;
      TurnControlUrl: string;
    },
    MediaRegion: string;
    MeetingId: string;
  }
}

export enum MessageEventType {
  IMAGE_ADDED = 'image:added',
  IMAGE_DELETED = 'image:deleted',
  IMAGE_ATTACHED = 'image:attached',
  MEETING_ENDED = 'meeting:ended',
  VIDEO_PAUSED = 'video:paused',
  VIDEO_UNPAUSED = 'video:unpaused',
}

export type AttachImageTarget = 'damage' | 'report';

interface ChimeMessagePayload {
  event: MessageEventType;
  timestampMs: number;
  topic: string;
  senderAttendeeId: string;
  senderExternalUserId: string;
}

export interface MeetingEndingPayload extends ChimeMessagePayload {
  status: Status;
}

export interface MessageImageAddedPayload extends ChimeMessagePayload {
  image: string;
}

export interface MessageImageAttachedPayload extends ChimeMessagePayload {
  image: string;
  target: AttachImageTarget;
  damage?: string;
}

interface UploadValidationResponse {
  total: UploadValidationTotals;
  progress: number; // 0 - 100
  tokens: {
    [token: string]: UploadValidationTokenStatus;
  }
  status: string;
  missing: string[],
  uploaded: string[],
  completed: string[],
}

type UploadValidationStatus = 'missing' | 'uploaded' | 'completed';

interface UploadValidationTotals {
  tokens: number;
  uploaded: number;
  completed: number;
  missing: number;
}

interface UploadValidationTokenStatus {
  preview: boolean;
  original: boolean;
  completed: boolean;
}

interface Attendee {
  presentAttendeeId: string;
  externalUserId: string;
}

interface AttendeeMeta {
  muted?: boolean;
  volume?: number;
  signalStrength?: number;
  isPaused?: boolean;
}
