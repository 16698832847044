var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _c(
        "v-layout",
        { staticClass: "chat-room-container fill-height", attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "fill-height": "" } }, [
            _c(
              "div",
              {
                staticClass: "elementPanel elementPanel--noPadding fill-height"
              },
              [
                _vm.isLoading
                  ? _c(
                      "v-layout",
                      { attrs: { wrap: "", "fill-height": "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("LoaderCard", {
                              staticClass: "fill-height",
                              attrs: { flat: "", type: "spinner--center" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isLoading
                  ? [
                      _c(
                        "v-layout",
                        {
                          staticClass: "flow-column",
                          attrs: { "fill-height": "" }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "fill-height": "" } },
                                [
                                  _vm.isSessionEnded && _vm.isLeader
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "chat-room-sidepanel",
                                          attrs: { xs2: "", "pa-0": "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { column: "" } },
                                            [
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "h4",
                                                      {
                                                        staticClass:
                                                          "\n                            sidepanel__content__title\n                            sidepanel__content__title--border-bottom\n                            sidepanel__content__title--darker\n                            font-weight-medium\n                            mb-3\n                          "
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Samenvatting gesprek "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-layout",
                                                      {
                                                        staticClass: "pa-0",
                                                        attrs: { wrap: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass: "mb-3",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-center"
                                                              },
                                                              [
                                                                _vm.applicantUpload
                                                                  ? _c(
                                                                      "v-progress-circular",
                                                                      {
                                                                        attrs: {
                                                                          rotate: -90,
                                                                          size: 150,
                                                                          width: 15,
                                                                          value:
                                                                            _vm
                                                                              .applicantUpload
                                                                              .progress,
                                                                          color:
                                                                            _vm
                                                                              .applicantUpload
                                                                              .progress ==
                                                                            100
                                                                              ? "secondary"
                                                                              : "primary"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .applicantUpload
                                                                                .total
                                                                                .completed
                                                                            ) +
                                                                            " / " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .applicantUpload
                                                                                .total
                                                                                .tokens
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass: "mb-2",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "separator"
                                                            })
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                staticClass:
                                                                  "sidepanel__content",
                                                                attrs: {
                                                                  wrap: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2",
                                                                    attrs: {
                                                                      xs12: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "label mb-1"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Status"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "value disabled"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            class:
                                                                              "statusPill statusBackgroundColor--" +
                                                                              _vm.statusColor
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.statusLabel
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    staticClass:
                                                                      "mb-2",
                                                                    attrs: {
                                                                      xs12: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "label mb-1"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Aantal foto's"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "value disabled"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .images
                                                                                .length
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "chat-room-sidepanel",
                                      class: _vm.chatRoomSidepanelClasses,
                                      attrs: { xs4: "", "pa-0": "" }
                                    },
                                    [
                                      _c(
                                        "v-navigation-drawer",
                                        {
                                          attrs: {
                                            absolute: "",
                                            temporary: ""
                                          },
                                          model: {
                                            value: _vm.showDrawer,
                                            callback: function($$v) {
                                              _vm.showDrawer = $$v
                                            },
                                            expression: "showDrawer"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "drawer-header" },
                                            [
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "elementSubTitle pa-2"
                                                },
                                                [_vm._v("Alle schades")]
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "drawer-toggle",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.showDrawer = false
                                                    }
                                                  }
                                                },
                                                [_vm._v("close")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "questions-reports"
                                            },
                                            [
                                              _vm._l(_vm.damages, function(
                                                damage,
                                                index
                                              ) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      key: "1-" + index,
                                                      staticClass:
                                                        "damage-item-container damage-item-container--bordered py-2",
                                                      class: {
                                                        active:
                                                          _vm.activeDamage &&
                                                          _vm.activeDamage
                                                            .uuid ===
                                                            damage.uuid
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.setActiveDamage(
                                                            damage,
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "damage-item__content px-3"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "damage-item__description pl-0"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "d-block font-weight-bold"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        index +
                                                                          1
                                                                      ) +
                                                                      ". " +
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "concatenateString"
                                                                        )(
                                                                          damage.name.replace(
                                                                            /\s/g,
                                                                            ""
                                                                          )
                                                                            .length
                                                                            ? damage.name
                                                                            : "Nieuwe schade " +
                                                                                (index +
                                                                                  1),
                                                                          30
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "damage-item__actions"
                                                            },
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                {},
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "exit_to_app"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Bekijk"
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              })
                                            ],
                                            2
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-layout",
                                        { attrs: { column: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                "sidepanel__content flex-0 pa-0",
                                              attrs: {
                                                xs12: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    "justify-space-between": "",
                                                    "align-center": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { grow: "" } },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        [
                                                          _c(
                                                            "v-tabs",
                                                            {
                                                              attrs: {
                                                                "slider-color":
                                                                  "secondary"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.activeTab,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.activeTab = $$v
                                                                },
                                                                expression:
                                                                  "activeTab"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-tab",
                                                                {
                                                                  staticClass:
                                                                    "tab__filled",
                                                                  class: {
                                                                    active:
                                                                      _vm.activeTab ===
                                                                      "report"
                                                                  },
                                                                  attrs: {
                                                                    href:
                                                                      "#report"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Rapport "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm.isLeader
                                                                ? _c(
                                                                    "v-tab",
                                                                    {
                                                                      staticClass:
                                                                        "tab__filled",
                                                                      class: {
                                                                        active:
                                                                          _vm.activeTab ===
                                                                          "damages"
                                                                      },
                                                                      attrs: {
                                                                        href:
                                                                          "#damages"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Schades "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "v-tab",
                                                                {
                                                                  staticClass:
                                                                    "tab__filled",
                                                                  class: {
                                                                    active:
                                                                      _vm.activeTab ===
                                                                      "images"
                                                                  },
                                                                  attrs: {
                                                                    href:
                                                                      "#images"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Foto's "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-tab",
                                                                {
                                                                  staticClass:
                                                                    "tab__filled",
                                                                  class: {
                                                                    active:
                                                                      _vm.activeTab ===
                                                                      "applicant"
                                                                  },
                                                                  attrs: {
                                                                    href:
                                                                      "#applicant"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Aanvrager "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  !_vm.isSessionEnded
                                                    ? _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            shrink: "",
                                                            "pr-3": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "sidepanel__content-toggle",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.isDisplayingSidePanel = false
                                                                }
                                                              }
                                                            },
                                                            [_vm._v(" close ")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "report-container",
                                              class: {
                                                "d-none":
                                                  _vm.activeTab !== "report",
                                                "report-container--withImages":
                                                  _vm.isLeader
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "report-container__content overflow-auto",
                                                  class: {
                                                    "report-container__content--withImages":
                                                      _vm.isLeader
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "\n                            sidepanel__content__title sidepanel__content__title--border-bottom\n                            font-weight-medium\n                            mb-3\n                          "
                                                    },
                                                    [_vm._v(" Rapport ")]
                                                  ),
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      staticClass:
                                                        "sidepanel__content py-0",
                                                      attrs: { wrap: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass: "mb-2",
                                                          attrs: { xs12: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "label"
                                                            },
                                                            [_vm._v("Naam")]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "value disabled"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.report
                                                                    .case_number
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass: "mb-2",
                                                          attrs: { xs12: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Rapport type"
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "value disabled"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.report
                                                                      .type.name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass: "mb-2",
                                                          attrs: { xs12: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Opname variant"
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "value disabled"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.report
                                                                      .parsedOpnameVariant
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          staticClass: "mb-2",
                                                          attrs: { xs12: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "label"
                                                            },
                                                            [_vm._v("Adres")]
                                                          ),
                                                          _vm.report.address
                                                            ? _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: _vm.report.googleMapUrl(),
                                                                    target:
                                                                      "_blank"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "value disabled d-inline-block"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .address
                                                                              .street
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .address
                                                                              .number
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .address
                                                                              .number_add
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                      _c("br"),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .address
                                                                              .postcode
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .address
                                                                              .city
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm.hasSubmission
                                                    ? [
                                                        _c(
                                                          "h4",
                                                          {
                                                            staticClass:
                                                              "\n                              sidepanel__content__title sidepanel__content__title--border-bottom\n                              mb-3\n                              font-weight-medium\n                            "
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Aanvullende gegevens "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            staticClass:
                                                              "sidepanel__content py-0",
                                                            attrs: { wrap: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                staticClass:
                                                                  "mb-2",
                                                                attrs: {
                                                                  xs12: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "label"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Aanvullende vraag"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "value"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .meeting
                                                                            .submission
                                                                            .additional_request
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _vm.getSubmissionDamage(
                                                              _vm.activeDamage
                                                            ) &&
                                                            _vm.getSubmissionDamage(
                                                              _vm.activeDamage
                                                            ).answers &&
                                                            _vm.getSubmissionDamage(
                                                              _vm.activeDamage
                                                            ).answers
                                                              .AdditionalRequestRemark
                                                              ? _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs12: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "mb-1"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Reactie aanvrager"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "fadedText"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.getSubmissionDamage(
                                                                                _vm.activeDamage
                                                                              )
                                                                                .answers
                                                                                .AdditionalRequestRemark
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                staticClass:
                                                                  "mb-2",
                                                                attrs: {
                                                                  xs12: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "label"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Reactie aanvrager"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm.meeting &&
                                                                _vm.meeting
                                                                  .submission &&
                                                                _vm.meeting
                                                                  .submission
                                                                  .data &&
                                                                _vm.meeting
                                                                  .submission
                                                                  .data.answers
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "value"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .meeting
                                                                                .submission
                                                                                .data
                                                                                .answers
                                                                                .AdditionalRequestRemark
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "value"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "-"
                                                                        )
                                                                      ]
                                                                    )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    : _vm._e(),
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "\n                            sidepanel__content__title sidepanel__content__title--border-bottom\n                            mb-3\n                            font-weight-medium\n                          "
                                                    },
                                                    [_vm._v(" Risico profiel ")]
                                                  ),
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      staticClass:
                                                        "sidepanel__content py-0",
                                                      attrs: { wrap: "" }
                                                    },
                                                    [
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass: "mb-2",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "label"
                                                              },
                                                              [_vm._v("Gebied")]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "value"
                                                              },
                                                              [
                                                                _vm.report
                                                                  .risk_profile
                                                                  .gasveld &&
                                                                _vm.report
                                                                  .risk_profile
                                                                  .gasveld
                                                                  .length > 1
                                                                  ? [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .risk_profile
                                                                              .gasveld
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  : _vm.report
                                                                      .risk_profile
                                                                      .gasveld_6km &&
                                                                    _vm.report
                                                                      .risk_profile
                                                                      .gasveld_6km
                                                                      .length >
                                                                      1
                                                                  ? [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .risk_profile
                                                                              .gasveld_6km
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  : [
                                                                      _vm._v(
                                                                        "Overig effectgebied"
                                                                      )
                                                                    ]
                                                              ],
                                                              2
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass: "mb-2",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "# aardbevingen met een PGV >2.0"
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "value"
                                                              },
                                                              [
                                                                _vm.report
                                                                  .risk_profile &&
                                                                _vm.report
                                                                  .risk_profile
                                                                  .earthquakes_accountable
                                                                  ? [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .risk_profile
                                                                              .earthquakes_accountable
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  : [
                                                                      _vm._v(
                                                                        "-"
                                                                      )
                                                                    ]
                                                              ],
                                                              2
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass: "mb-2",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Zwaarste Aardbeving"
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "value"
                                                              },
                                                              [
                                                                _vm.report
                                                                  .risk_profile &&
                                                                _vm.report
                                                                  .risk_profile
                                                                  .heaviest
                                                                  ? [
                                                                      _vm._v(
                                                                        " M" +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .risk_profile
                                                                              .heaviest
                                                                              .magnitude
                                                                          ) +
                                                                          " - " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .report
                                                                              .risk_profile
                                                                              .heaviest
                                                                              .city
                                                                          ) +
                                                                          " op " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "dateFormat"
                                                                            )(
                                                                              _vm
                                                                                .report
                                                                                .risk_profile
                                                                                .heaviest
                                                                                .occurred_at
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  : [
                                                                      _vm._v(
                                                                        "-"
                                                                      )
                                                                    ]
                                                              ],
                                                              2
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    staticClass:
                                                                      "mr-1 mb-2",
                                                                    attrs: {
                                                                      shrink: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "label mb-1"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Max. PGV 25%"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "value"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            class:
                                                                              "statusPill statusBackgroundColor--secondary"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .report
                                                                                    .risk_profile
                                                                                    .pgv_25pct
                                                                                ) +
                                                                                " mm/s "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    staticClass:
                                                                      "ml-1 mb-2",
                                                                    attrs: {
                                                                      shrink: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "label mb-1"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Max. PGV 1%"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "value"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            class:
                                                                              "statusPill statusBackgroundColor--primary"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .report
                                                                                    .risk_profile
                                                                                    .pgv_1pct
                                                                                ) +
                                                                                " mm/s "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm.report.risk_profile
                                                          .project_media &&
                                                        _vm.report.risk_profile
                                                          .project_media
                                                          .length > 0
                                                          ? [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "mb-2",
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "label"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Instructies"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "value"
                                                                    },
                                                                    _vm._l(
                                                                      _vm.report
                                                                        .risk_profile
                                                                        .project_media,
                                                                      function(
                                                                        media
                                                                      ) {
                                                                        return _c(
                                                                          "p",
                                                                          {
                                                                            key:
                                                                              "project-media-" +
                                                                              media.uuid +
                                                                              "-cell"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "a",
                                                                              {
                                                                                attrs: {
                                                                                  href:
                                                                                    media.original,
                                                                                  target:
                                                                                    "_blank"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "picture_as_pdf"
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      media.description
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          : _vm._e(),
                                                        _vm.report
                                                          .risk_profile &&
                                                        _vm.report.risk_profile
                                                          .heaviest &&
                                                        _vm.report.bouwjaar >=
                                                          _vm.report.risk_profile.heaviest.occurred_at.substring(
                                                            0,
                                                            4
                                                          )
                                                          ? [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "mb-2",
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "label"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Waarschuwing"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "value"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "p",
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "warning"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " Bouwjaar " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .report
                                                                                  .bouwjaar
                                                                              ) +
                                                                              " later dan zwaarste beving (" +
                                                                              _vm._s(
                                                                                _vm.report.risk_profile.heaviest.occurred_at.substring(
                                                                                  0,
                                                                                  4
                                                                                )
                                                                              ) +
                                                                              ") "
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          : _vm._e()
                                                      ]
                                                    ],
                                                    2
                                                  )
                                                ],
                                                2
                                              ),
                                              _vm.isLeader
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "grid-item"
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "\n                            sidepanel__content__title sidepanel__content__title--border-bottom\n                            font-weight-medium\n                          "
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Foto's (" +
                                                              _vm._s(
                                                                _vm.reportImages
                                                                  .length
                                                              ) +
                                                              ") "
                                                          )
                                                        ]
                                                      ),
                                                      _vm.reportImages.length
                                                        ? [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "sidepanel__content__media overflow-auto"
                                                              },
                                                              _vm._l(
                                                                _vm.reportImages,
                                                                function(
                                                                  image,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key:
                                                                        "report-images-" +
                                                                        index,
                                                                      staticClass:
                                                                        "mediaContainer mediaContainer--clipped"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mediaContainer__actions"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "action",
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onClickOpenImageDialog(
                                                                                    image
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "remove_red_eye"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm.hasMediaDeletionPermission
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "action",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.onClickOpenDeleteImageDialog(
                                                                                        image
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "delete"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      ),
                                                                      _vm.isLeader
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mediaContainer__statusIcon",
                                                                              class:
                                                                                "statusIcon--" +
                                                                                _vm.imageUploadedStatusIconClass(
                                                                                  image
                                                                                )
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.imageUploadedStatusIcon(
                                                                                        image
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs: {
                                                                            src:
                                                                              image.original
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          ]
                                                        : _c("NoDataCard", {
                                                            attrs: {
                                                              icon: "image",
                                                              text:
                                                                "Geen foto's"
                                                            }
                                                          })
                                                    ],
                                                    2
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "damage-container",
                                              class: {
                                                "d-none":
                                                  _vm.activeTab !== "damages"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "grid-item" },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "\n                            sidepanel__content__title sidepanel__content__title--border-bottom\n                            font-weight-medium\n                          "
                                                    },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        {
                                                          attrs: {
                                                            "align-center": "",
                                                            "justify-space-between":
                                                              ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                shrink: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "cursor-pointer",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.showDrawer = true
                                                                                    }
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "list"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    1343665035
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Schade overzicht"
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    "justify-center":
                                                                      "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm.activeDamage &&
                                                                  _vm.damages
                                                                    ? _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "cursor-pointer px-2",
                                                                                          class: {
                                                                                            disabled:
                                                                                              _vm.activeDamageIndex ===
                                                                                              0
                                                                                          },
                                                                                          on: {
                                                                                            click: function(
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.setActiveDamage(
                                                                                                _vm
                                                                                                  .damages[
                                                                                                  _vm.activeDamageIndex -
                                                                                                    1
                                                                                                ],
                                                                                                _vm.activeDamageIndex -
                                                                                                  1
                                                                                              )
                                                                                            }
                                                                                          }
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          " chevron_left "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            838101106
                                                                          )
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Volgende schade"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.activeDamage
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.activeDamageIndex +
                                                                                  1
                                                                              ) +
                                                                              ". " +
                                                                              _vm._s(
                                                                                _vm.activeDamage.name.replace(
                                                                                  /\s/g,
                                                                                  ""
                                                                                )
                                                                                  .length
                                                                                  ? _vm
                                                                                      .activeDamage
                                                                                      .name
                                                                                  : "Nieuwe schade " +
                                                                                      (_vm.activeDamageIndex +
                                                                                        1)
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  !_vm.activeDamage &&
                                                                  _vm.damages
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Geen schade geselecteerd "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  !_vm.activeDamage &&
                                                                  !_vm.damages
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Geen schades gevonden "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.activeDamage &&
                                                                  _vm.damages
                                                                    ? _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "cursor-pointer px-2",
                                                                                          class: {
                                                                                            disabled:
                                                                                              _vm.activeDamageIndex +
                                                                                                1 ===
                                                                                              _vm
                                                                                                .damages
                                                                                                .length
                                                                                          },
                                                                                          on: {
                                                                                            click: function(
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.setActiveDamage(
                                                                                                _vm
                                                                                                  .damages[
                                                                                                  _vm.activeDamageIndex +
                                                                                                    1
                                                                                                ],
                                                                                                _vm.activeDamageIndex +
                                                                                                  1
                                                                                              )
                                                                                            }
                                                                                          }
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          " chevron_right "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            1790212649
                                                                          )
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Volgende schade"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          !_vm.isDisabled &&
                                                          !_vm.isProduction
                                                            ? _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    shrink: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm.isCreatingDamage
                                                                    ? _c(
                                                                        "LoaderCard",
                                                                        {
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                            type:
                                                                              "spinner--small"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.activeDamage
                                                                    ? _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "mr-2 cursor-pointer",
                                                                                          on: {
                                                                                            click: function(
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.isDuplicatingDamage = true
                                                                                            }
                                                                                          }
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          " file_copy "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            2591746338
                                                                          )
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Schade dupliceren"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  !_vm.isCreatingDamage
                                                                    ? _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "cursor-pointer",
                                                                                          on: {
                                                                                            click: function(
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.createDamage()
                                                                                            }
                                                                                          }
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          "add"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            2893724723
                                                                          )
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Schade toevoegen"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "grid-item grid-item--questionair overflow-auto"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _vm.isLoadingQuestions
                                                        ? _c("LoaderCard", {
                                                            staticClass:
                                                              "pa-3 fill-height",
                                                            attrs: {
                                                              flat: "",
                                                              type:
                                                                "spinner--center"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      !_vm.isLoadingQuestions &&
                                                      _vm.activeDamage
                                                        ? [
                                                            _vm.getSubmissionDamage(
                                                              _vm.activeDamage
                                                            ) &&
                                                            _vm.hasSubmission
                                                              ? _c(
                                                                  "v-layout",
                                                                  {
                                                                    attrs: {
                                                                      wrap: "",
                                                                      "pa-3": ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs12:
                                                                            "",
                                                                          "mb-2":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Aanvullende vraag"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "fadedText"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.getSubmissionDamage(
                                                                                    _vm.activeDamage
                                                                                  )
                                                                                    .additional_request
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs12:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Reactie aanvrager"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm.getSubmissionDamage(
                                                                          _vm.activeDamage
                                                                        ) &&
                                                                        _vm.getSubmissionDamage(
                                                                          _vm.activeDamage
                                                                        )
                                                                          .answers &&
                                                                        _vm.getSubmissionDamage(
                                                                          _vm.activeDamage
                                                                        )
                                                                          .answers
                                                                          .AdditionalRequestRemark
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "fadedText"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.getSubmissionDamage(
                                                                                        _vm.activeDamage
                                                                                      )
                                                                                        .answers
                                                                                        .AdditionalRequestRemark
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "fadedText"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "-"
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "h4",
                                                              {
                                                                staticClass:
                                                                  "\n                                sidepanel__content__title sidepanel__content__title--border-bottom\n                                font-weight-medium\n                              "
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Vragenlijst "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "Questionnaire",
                                                              {
                                                                staticClass:
                                                                  "px-3 py-1",
                                                                attrs: {
                                                                  questions:
                                                                    _vm.damageQuestions,
                                                                  damage:
                                                                    _vm.activeDamage,
                                                                  answers:
                                                                    _vm
                                                                      .activeDamage
                                                                      .answers,
                                                                  report:
                                                                    _vm.report,
                                                                  enableReadMode:
                                                                    _vm.$store
                                                                      .state
                                                                      .Auth
                                                                      .has_quick_questions
                                                                },
                                                                on: {
                                                                  damageAnswerSaved:
                                                                    _vm.damageAnswerSaved
                                                                }
                                                              }
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "grid-item" },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "\n                            sidepanel__content__title sidepanel__content__title--border-bottom\n                            font-weight-medium\n                          "
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Foto's (" +
                                                          _vm._s(
                                                            _vm.damageImages
                                                              .length
                                                          ) +
                                                          ") "
                                                      )
                                                    ]
                                                  ),
                                                  _vm.damageImages.length
                                                    ? [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "sidepanel__content__media overflow-auto"
                                                          },
                                                          _vm._l(
                                                            _vm.damageImages,
                                                            function(
                                                              image,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    "damage-images-" +
                                                                    index,
                                                                  staticClass:
                                                                    "mediaContainer mediaContainer--clipped"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "mediaContainer__actions"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "action",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.onClickOpenImageDialog(
                                                                                image
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "remove_red_eye"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm.hasMediaDeletionPermission
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "action",
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onClickOpenDeleteImageDialog(
                                                                                    image
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "delete"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  ),
                                                                  _vm.isLeader
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "mediaContainer__statusIcon",
                                                                          class:
                                                                            "statusIcon--" +
                                                                            _vm.imageUploadedStatusIconClass(
                                                                              image
                                                                            )
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.imageUploadedStatusIcon(
                                                                                    image
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src:
                                                                        image.original
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      ]
                                                    : _c("NoDataCard", {
                                                        attrs: {
                                                          icon: "image",
                                                          text: "Geen foto's"
                                                        }
                                                      })
                                                ],
                                                2
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "images-container",
                                              class: {
                                                "d-none":
                                                  _vm.activeTab !== "images"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "grid-item" },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "\n                            sidepanel__content__title sidepanel__content__title--border-bottom\n                            font-weight-medium\n                          "
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Foto's (" +
                                                          _vm._s(
                                                            _vm.images.length
                                                          ) +
                                                          ") "
                                                      ),
                                                      _vm.isSessionEnded
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "icon__legenda"
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "legenda__item",
                                                                      attrs: {
                                                                        shrink:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "statusIcon statusIcon--missing"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "hourglass_empty"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "In afwachting"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "legenda__item",
                                                                      attrs: {
                                                                        shrink:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "statusIcon statusIcon--primary"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "cloud"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Geüpload"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "legenda__item",
                                                                      attrs: {
                                                                        shrink:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "statusIcon statusIcon--completed"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "done"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Verwerkt"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "grid-item grid-item--images overflow-auto"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "pa-3" },
                                                    [
                                                      _vm.images.length
                                                        ? [
                                                            _vm.hasMediaReorderPermission
                                                              ? _c(
                                                                  "DraggableContent",
                                                                  {
                                                                    attrs: {
                                                                      draggables:
                                                                        _vm.images
                                                                    },
                                                                    on: {
                                                                      handleDragEndEvent:
                                                                        _vm.onImageDragEnd
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot:
                                                                          "content"
                                                                      },
                                                                      _vm._l(
                                                                        _vm.images,
                                                                        function(
                                                                          image,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key:
                                                                                "images-" +
                                                                                index,
                                                                              staticClass:
                                                                                "mediaContainer mediaContainer--clipped",
                                                                              class: {
                                                                                "mediaContainer--medium":
                                                                                  _vm.isSessionEnded
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "mediaContainer__actions"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "action",
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.onClickOpenImageDialog(
                                                                                            image
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "remove_red_eye"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm.hasMediaDeletionPermission
                                                                                    ? _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "action",
                                                                                          on: {
                                                                                            click: function(
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.onClickOpenDeleteImageDialog(
                                                                                                image
                                                                                              )
                                                                                            }
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "delete"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              ),
                                                                              (_vm.isLeader &&
                                                                                image &&
                                                                                image.meta_meeting &&
                                                                                image
                                                                                  .meta_meeting
                                                                                  .is_assigned) ||
                                                                              _vm.imageIsStatus(
                                                                                image,
                                                                                "completed"
                                                                              )
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mediaContainer__statusIcon",
                                                                                      class:
                                                                                        "statusIcon--" +
                                                                                        _vm.imageUploadedStatusIconClass(
                                                                                          image
                                                                                        )
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.imageUploadedStatusIcon(
                                                                                                image
                                                                                              )
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              _c(
                                                                                "img",
                                                                                {
                                                                                  attrs: {
                                                                                    src:
                                                                                      image.original
                                                                                  }
                                                                                }
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                              : _vm._e(),
                                                            !_vm.hasMediaReorderPermission
                                                              ? _vm._l(
                                                                  _vm.images,
                                                                  function(
                                                                    image,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key:
                                                                          "images-" +
                                                                          index,
                                                                        staticClass:
                                                                          "mediaContainer mediaContainer--clipped",
                                                                        class: {
                                                                          "mediaContainer--medium":
                                                                            _vm.isSessionEnded
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "mediaContainer__actions"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "action",
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.onClickOpenImageDialog(
                                                                                      image
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "remove_red_eye"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm.hasMediaDeletionPermission
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "action",
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.onClickOpenDeleteImageDialog(
                                                                                          image
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "delete"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        ),
                                                                        (_vm.isLeader &&
                                                                          image &&
                                                                          image.meta_meeting &&
                                                                          image
                                                                            .meta_meeting
                                                                            .is_assigned) ||
                                                                        _vm.imageIsStatus(
                                                                          image,
                                                                          "completed"
                                                                        )
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "mediaContainer__statusIcon",
                                                                                class:
                                                                                  "statusIcon--" +
                                                                                  _vm.imageUploadedStatusIconClass(
                                                                                    image
                                                                                  )
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.imageUploadedStatusIcon(
                                                                                          image
                                                                                        )
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            attrs: {
                                                                              src:
                                                                                image.original
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        : _c("NoDataCard", {
                                                            attrs: {
                                                              icon: "image",
                                                              text:
                                                                "Geen foto's"
                                                            }
                                                          })
                                                    ],
                                                    2
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "applicant-container",
                                              class: {
                                                "d-none":
                                                  _vm.activeTab !== "applicant"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "applicant-list"
                                                },
                                                [
                                                  _vm.applicantPermissions
                                                    .length
                                                    ? _vm._l(
                                                        _vm.applicantPermissions,
                                                        function(
                                                          permission,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "applicant-list__item"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "item__overlay"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "item__icon-back"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.checklistIcon(
                                                                              permission.key
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "item__icon",
                                                                      class:
                                                                        permission.status
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.checklistStatusIcon(
                                                                              permission.status
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c("div", [
                                                                _c(
                                                                  "h4",
                                                                  {
                                                                    staticClass:
                                                                      "item__title"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        permission.label
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "item__description"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        permission.description
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  !_vm.applicantPermissions
                                                    .length
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "applicant-list__item applicant-list__item--empty"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "item__overlay"
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "item__icon-back"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-account-alert"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "item__description"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "De aanvrager heeft de mobiele app nog niet geopend."
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                2
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "applicant-list"
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass: "item__title"
                                                    },
                                                    [_vm._v("Bijgewerkt op:")]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "item__description"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.applicantPermissionsUpdatedAt
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.isDisplayingChatRoomContent
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "chat-room-content",
                                          class: _vm.chatRoomContentClasses
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": "",
                                                "align-content-center": "",
                                                "justify-center": "",
                                                "fill-height": ""
                                              }
                                            },
                                            [
                                              _vm.isDisplayingChatRoom
                                                ? [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs12: "",
                                                          "text-xs-center": "",
                                                          "mb-2": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "h2",
                                                          {
                                                            staticClass:
                                                              "chat-room__name"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Welkom, " +
                                                                _vm._s(
                                                                  _vm.$store
                                                                    .state.Auth
                                                                    .name
                                                                )
                                                            )
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _vm.isMeetingEndedForIMG
                                                          ? _c(
                                                              "h4",
                                                              {
                                                                staticClass:
                                                                  "chat-room__name"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Afspraak is al geweest"
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs12: "",
                                                          "text-xs-center": "",
                                                          "my-2": ""
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass:
                                                            "chat-room__avatar",
                                                          attrs: {
                                                            src: _vm.userAvatar
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs12: "",
                                                          "text-xs-center": ""
                                                        }
                                                      },
                                                      [
                                                        _vm.isMeetingEndedForIMG
                                                          ? [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Gesprek is Beëindigd"
                                                                )
                                                              ])
                                                            ]
                                                          : _vm._e(),
                                                        !_vm.isMeetingEndedForIMG
                                                          ? _c(
                                                              "MiButton",
                                                              {
                                                                attrs: {
                                                                  round: true
                                                                },
                                                                nativeOn: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.initializeAmazonChime()
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Gesprek starten "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "mt-3"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "fadedText link",
                                                                on: {
                                                                  click:
                                                                    _vm.navigateToReport
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Terug naar rapport"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ]
                                                : [
                                                    _c("audio", {
                                                      staticClass:
                                                        "attendee-audio-element"
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "chat-room-actionbar-top"
                                                      },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: {
                                                              wrap: "",
                                                              "justify-space-between":
                                                                "",
                                                              "align-center":
                                                                "",
                                                              "fill-height": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  shrink: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "chat-room-settings"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          bottom:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                return [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    _vm._g(
                                                                                      {
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.isDisplayingSettingsDialog = true
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "settings"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          false,
                                                                          3089846857
                                                                        )
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Instellingen"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "chat-room-attendees-bottom"
                                                      },
                                                      _vm._l(
                                                        _vm.allAttendees,
                                                        function(user, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: "0-" + index,
                                                              staticClass:
                                                                "chat-room__user-item-container py-3",
                                                              class: {
                                                                speaking:
                                                                  _vm
                                                                    .allAttendeesMeta[
                                                                    user
                                                                      .externalUserId
                                                                  ] &&
                                                                  _vm
                                                                    .allAttendeesMeta[
                                                                    user
                                                                      .externalUserId
                                                                  ].volume >
                                                                    0 &&
                                                                  !_vm
                                                                    .allAttendeesMeta[
                                                                    user
                                                                      .externalUserId
                                                                  ].muted
                                                              }
                                                            },
                                                            [
                                                              user
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "user-item__content"
                                                                    },
                                                                    [
                                                                      _vm.findParticipants(
                                                                        user.externalUserId
                                                                      )
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "user-item__description"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "pr-2"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .icons[
                                                                                          _vm.findParticipants(
                                                                                            user.externalUserId
                                                                                          )
                                                                                            .device
                                                                                        ] ||
                                                                                          "public"
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-block font-weight-bold"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.findParticipants(
                                                                                          user.externalUserId
                                                                                        )
                                                                                          .name
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "user-item__actions"
                                                                        },
                                                                        [
                                                                          _vm
                                                                            .allAttendeesMeta[
                                                                            user
                                                                              .externalUserId
                                                                          ]
                                                                            ? [
                                                                                _vm
                                                                                  .allAttendeesMeta[
                                                                                  user
                                                                                    .externalUserId
                                                                                ]
                                                                                  .signalStrength <=
                                                                                0
                                                                                  ? _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "actions__alert-icon"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " mdi-wifi-alert-outline "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm
                                                                                  .allAttendeesMeta[
                                                                                  user
                                                                                    .externalUserId
                                                                                ]
                                                                                  .signalStrength >
                                                                                  0 &&
                                                                                _vm
                                                                                  .allAttendeesMeta[
                                                                                  user
                                                                                    .externalUserId
                                                                                ]
                                                                                  .signalStrength <=
                                                                                  0.5
                                                                                  ? _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "actions__warning-icon"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " mdi-wifi-strength-2 "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm
                                                                                  .allAttendeesMeta[
                                                                                  user
                                                                                    .externalUserId
                                                                                ]
                                                                                  .muted
                                                                                  ? [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          staticClass:
                                                                                            "actions__alert-icon"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "mic_off"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  : _vm._e(),
                                                                                !_vm
                                                                                  .allAttendeesMeta[
                                                                                  user
                                                                                    .externalUserId
                                                                                ]
                                                                                  .muted
                                                                                  ? [
                                                                                      _vm
                                                                                        .allAttendeesMeta[
                                                                                        user
                                                                                          .externalUserId
                                                                                      ]
                                                                                        .volume >
                                                                                      0
                                                                                        ? _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "volume_up"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e()
                                                                                    ]
                                                                                  : _vm._e()
                                                                              ]
                                                                            : _vm._e()
                                                                        ],
                                                                        2
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    !Object.keys(
                                                      _vm.externalTileStates
                                                    ) ||
                                                    !Object.keys(
                                                      _vm.externalTileStates
                                                    ).length
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "no-attendee-container"
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  "justify-center":
                                                                    "",
                                                                  "align-center":
                                                                    "",
                                                                  "fill-height":
                                                                    "",
                                                                  column: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "no-attendee__icon pb-3"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-video-off"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    Object.keys(
                                                      _vm.externalTileStates
                                                    ) &&
                                                    Object.keys(
                                                      _vm.externalTileStates
                                                    ).length
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "attendee-container",
                                                            style:
                                                              "grid-template-columns: repeat(" +
                                                              _vm.gridTemplateColumns +
                                                              ", 1fr);"
                                                          },
                                                          _vm._l(
                                                            _vm.externalTileStates,
                                                            function(
                                                              tile,
                                                              tileId
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: tileId,
                                                                  staticClass:
                                                                    "attendee-item",
                                                                  class: {
                                                                    "attendee-item--thumbnail":
                                                                      _vm.activeTileId &&
                                                                      _vm.activeTileId !==
                                                                        tile.tileId,
                                                                    "attendee-item--fullscreen":
                                                                      _vm.activeTileId &&
                                                                      _vm.activeTileId ===
                                                                        tile.tileId
                                                                  },
                                                                  style:
                                                                    "right: calc(136px * " +
                                                                    (_vm.getTileIdIndex(
                                                                      tile.tileId
                                                                    ) +
                                                                      1) +
                                                                    " + 8px)"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "attendee-video-container",
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "video",
                                                                        {
                                                                          staticClass:
                                                                            "attendee-video-element",
                                                                          attrs: {
                                                                            id:
                                                                              "attendee-video-element-" +
                                                                              tileId
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "chat-room-top-left"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "attendee-item-pin"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-layout",
                                                                                {
                                                                                  attrs: {
                                                                                    wrap:
                                                                                      "",
                                                                                    "justify-space-between":
                                                                                      "",
                                                                                    "align-center":
                                                                                      "",
                                                                                    "fill-height":
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-flex",
                                                                                    {
                                                                                      attrs: {
                                                                                        shrink:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "attendee-item-pin__icon"
                                                                                        },
                                                                                        [
                                                                                          _vm.activeTileId !==
                                                                                          tile.tileId
                                                                                            ? _c(
                                                                                                "v-tooltip",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    bottom:
                                                                                                      ""
                                                                                                  },
                                                                                                  scopedSlots: _vm._u(
                                                                                                    [
                                                                                                      {
                                                                                                        key:
                                                                                                          "activator",
                                                                                                        fn: function(
                                                                                                          ref
                                                                                                        ) {
                                                                                                          var on =
                                                                                                            ref.on
                                                                                                          return [
                                                                                                            _c(
                                                                                                              "v-icon",
                                                                                                              _vm._g(
                                                                                                                {
                                                                                                                  on: {
                                                                                                                    click: function(
                                                                                                                      $event
                                                                                                                    ) {
                                                                                                                      _vm.activeTileId =
                                                                                                                        tile.tileId
                                                                                                                    }
                                                                                                                  }
                                                                                                                },
                                                                                                                on
                                                                                                              ),
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "push_pin"
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ]
                                                                                                        }
                                                                                                      }
                                                                                                    ],
                                                                                                    null,
                                                                                                    true
                                                                                                  )
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Pin deelnemer"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          _vm.activeTileId ===
                                                                                          tile.tileId
                                                                                            ? _c(
                                                                                                "v-tooltip",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    bottom:
                                                                                                      ""
                                                                                                  },
                                                                                                  scopedSlots: _vm._u(
                                                                                                    [
                                                                                                      {
                                                                                                        key:
                                                                                                          "activator",
                                                                                                        fn: function(
                                                                                                          ref
                                                                                                        ) {
                                                                                                          var on =
                                                                                                            ref.on
                                                                                                          return [
                                                                                                            _c(
                                                                                                              "v-icon",
                                                                                                              _vm._g(
                                                                                                                {
                                                                                                                  staticClass:
                                                                                                                    "cancel",
                                                                                                                  on: {
                                                                                                                    click: function(
                                                                                                                      $event
                                                                                                                    ) {
                                                                                                                      _vm.activeTileId = null
                                                                                                                    }
                                                                                                                  }
                                                                                                                },
                                                                                                                on
                                                                                                              ),
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  " cancel "
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ]
                                                                                                        }
                                                                                                      }
                                                                                                    ],
                                                                                                    null,
                                                                                                    true
                                                                                                  )
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Verwijder pin deelnemer"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e()
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "attendee-video-paused",
                                                                          class: {
                                                                            "d-none":
                                                                              _vm
                                                                                .allAttendeesMeta[
                                                                                tile
                                                                                  .boundExternalUserId
                                                                              ] &&
                                                                              !_vm
                                                                                .allAttendeesMeta[
                                                                                tile
                                                                                  .boundExternalUserId
                                                                              ]
                                                                                .isPaused
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "attendee-video-paused__icon"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "photo_camera"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "attendee-video-paused__text"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "De aanvrager is bezig met een foto maken"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                    _vm.hasPermissionVideo
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "chat-room-actionbar-bottom"
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  shrink: "",
                                                                  "fill-height":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "internal-attendee-thumbnail"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "video",
                                                                      {
                                                                        staticClass:
                                                                          "internal-attendee-video-element"
                                                                      }
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { staticClass: "chat-room-actionbar" },
                            [
                              !_vm.isSessionEnded
                                ? [
                                    _vm.isSessionStarted
                                      ? [
                                          !_vm.isMuted
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "actionbar__action outline"
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.mute()
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mic"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        1248321615
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Microfoon uit")
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isMuted
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "actionbar__action close"
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.unmute()
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mic_off"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        1709508900
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Microfoon aan")
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "actionbar__action close"
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.leaveSession()
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "call_end"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3631773622
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Verlaat gesprek")
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "actionbar__action call",
                                              class: _vm.isMakingPhoneRing
                                                ? "disabled actionbar__action--align-end"
                                                : ""
                                            },
                                            [
                                              _vm.isMakingPhoneRing
                                                ? _c("LoaderCard", {
                                                    attrs: {
                                                      flat: "",
                                                      type: "spinner--small"
                                                    }
                                                  })
                                                : _vm._e(),
                                              !_vm.isMakingPhoneRing
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.onClickMakePhoneRing()
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "add_ic_call"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        2929253931
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Aanvrager bellen"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm.isLeader
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "actionbar__action actionbar__action--stopsession"
                                                },
                                                [
                                                  _c(
                                                    "MiButton",
                                                    {
                                                      attrs: {
                                                        color: "warning",
                                                        small: "true",
                                                        disabled:
                                                          _vm.isEndingSession
                                                      },
                                                      nativeOn: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.endSession()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Beëindig opname "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "actionbar__action",
                                        on: {
                                          click: function($event) {
                                            _vm.isDisplayingSidePanel = !_vm.isDisplayingSidePanel
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g({}, on),
                                                        [_vm._v("description")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1384520424
                                            )
                                          },
                                          [_c("span", [_vm._v("Rapport")])]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : [
                                    _c(
                                      "v-layout",
                                      {
                                        attrs: {
                                          "justify-space-between": "",
                                          "align-center": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "backContainer" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "back",
                                                on: {
                                                  click: _vm.navigateToReport
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("arrow_back")
                                                ]),
                                                _c("span", [
                                                  _vm._v("Terug naar rapport")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        !_vm.isDisabled && _vm.isLeader
                                          ? _c(
                                              "MiButton",
                                              {
                                                attrs: {
                                                  color: "warning",
                                                  small: "true",
                                                  disabled:
                                                    _vm.isEndingSubmission
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    return _vm.endSessionPermanent()
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " Beëindig opname op afstand "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.isDisabled && _vm.isLeader
                                          ? [
                                              !_vm.hasSubmission
                                                ? _c(
                                                    "MiButton",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        small: "true"
                                                      },
                                                      nativeOn: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.additionallyRequest()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Aanvullende gegevens "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.hasSubmission
                                                ? _c(
                                                    "MiButton",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        small: "true"
                                                      },
                                                      nativeOn: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.navigateToMsrEditor()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Aanvullende gegevens beheren "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        ],
        1
      ),
      _vm.isDisplayingPermissionDialog
        ? [
            _c("div", { staticClass: "dialog__overlay" }),
            _c("div", { staticClass: "permission__dialog" }, [
              _c(
                "div",
                { staticClass: "elementPanel fill-height overflow-auto" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("h2", { staticClass: "elementSubTitle" }, [
                            _vm._v("Geen toegang")
                          ]),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              staticClass: "dialog-close",
                                              on: {
                                                click: function($event) {
                                                  _vm.isDisplayingPermissionDialog = false
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v("close")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3542333772
                              )
                            },
                            [_c("span", [_vm._v("Sluiten")])]
                          )
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("p", [
                          _vm._v(
                            "We hebben jouw toestemming nodig om de camera en de microfoon te gebruiken."
                          )
                        ])
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    shrink: "",
                                    "text-center": "",
                                    "align-self-end": "",
                                    "mr-2": ""
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "cursor-pointer",
                                    attrs: {
                                      width: "56px",
                                      src: "/img/chrome.jpg",
                                      alt: "chrome_logo"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.activeBrowser = "chrome"
                                      }
                                    }
                                  }),
                                  _c(
                                    "p",
                                    {
                                      class: {
                                        "primary--text font-weight-bold":
                                          _vm.activeBrowser === "chrome"
                                      }
                                    },
                                    [_vm._v("Chrome")]
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    shrink: "",
                                    "text-center": "",
                                    "align-self-end": "",
                                    "ml-2": ""
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "cursor-pointer",
                                    attrs: {
                                      width: "56px",
                                      src: "/img/firefox.jpg",
                                      alt: "firefox_logo"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.activeBrowser = "firefox"
                                      }
                                    }
                                  }),
                                  _c(
                                    "p",
                                    {
                                      class: {
                                        "primary--text font-weight-bold":
                                          _vm.activeBrowser === "firefox"
                                      }
                                    },
                                    [_vm._v("Firefox")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.activeBrowser
                        ? _c("v-flex", { attrs: { xs12: "", "my-3": "" } }, [
                            _c("p", [
                              _vm._v(
                                "Voor hulp bij het instellen klik je op het logo van de browser"
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.activeBrowser
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", "my-3": "" } },
                            [
                              _vm.activeBrowser === "chrome"
                                ? [
                                    _c("p", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "primary--text subheading font-weight-bold"
                                        },
                                        [_vm._v("1.")]
                                      ),
                                      _vm._v(
                                        " Klik op het icoontje die je op de foto hieronder ziet. "
                                      )
                                    ]),
                                    _c("img", {
                                      staticClass: "mb-2",
                                      attrs: {
                                        src: "/img/chrome_tab.png",
                                        alt: "chrome_tab"
                                      }
                                    }),
                                    _c("p", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "primary--text subheading font-weight-bold"
                                        },
                                        [_vm._v("2.")]
                                      ),
                                      _vm._v(
                                        " Klik op instellingen (settings) onderaan de popup. "
                                      )
                                    ]),
                                    _c("p", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "primary--text subheading font-weight-bold"
                                        },
                                        [_vm._v("3.")]
                                      ),
                                      _vm._v(
                                        " In deze lijst kun je toegang geven van je camera en/of microfoon. "
                                      )
                                    ]),
                                    _c("img", {
                                      staticClass: "mb-2",
                                      attrs: {
                                        width: "500px",
                                        src: "/img/chrome_permission.png",
                                        alt: "chrome_settings"
                                      }
                                    }),
                                    _c("p", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "primary--text subheading font-weight-bold"
                                        },
                                        [_vm._v("4.")]
                                      ),
                                      _vm._v(
                                        " Nadat je deze stappen hebt doorgevoerd moet de pagina ververst worden. "
                                      )
                                    ])
                                  ]
                                : _vm._e(),
                              _vm.activeBrowser === "firefox"
                                ? [
                                    _c("p", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "primary--text subheading font-weight-bold"
                                        },
                                        [_vm._v("1.")]
                                      ),
                                      _vm._v(
                                        " Klik op het icoontje die je op de foto hieronder ziet. "
                                      )
                                    ]),
                                    _c("img", {
                                      staticClass: "mb-2",
                                      attrs: {
                                        src: "/img/firefox_tab.png",
                                        alt: "firefox_tab"
                                      }
                                    }),
                                    _c("p", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "primary--text subheading font-weight-bold"
                                        },
                                        [_vm._v("2.")]
                                      ),
                                      _vm._v(
                                        " Onder toestemmingen kun je zien of je toegang hebt gegeven. "
                                      )
                                    ]),
                                    _c("p", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "primary--text subheading font-weight-bold"
                                        },
                                        [_vm._v("3.")]
                                      ),
                                      _vm._v(
                                        ' Bij "Tijdelijk geblokeerd" klik je op het kruisje om toegang te geven. '
                                      )
                                    ]),
                                    _c("p", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "primary--text subheading font-weight-bold"
                                        },
                                        [_vm._v("4.")]
                                      ),
                                      _vm._v(
                                        " Nadat je deze stappen hebt doorgevoerd moet de pagina ververst worden. "
                                      )
                                    ])
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          staticClass: "snackbar",
          attrs: { timeout: 5000 },
          model: {
            value: _vm.isDisplayingSnackbar,
            callback: function($$v) {
              _vm.isDisplayingSnackbar = $$v
            },
            expression: "isDisplayingSnackbar"
          }
        },
        [
          _c(
            "v-layout",
            {
              attrs: {
                "justify-space-between": "",
                "align-center": "",
                "fill-height": ""
              }
            },
            [
              _c("v-flex", { staticClass: "d-flex" }, [
                _vm.snackbarImage
                  ? _c("img", {
                      staticClass: "snackbar__image",
                      attrs: {
                        src: _vm.snackbarImage.original,
                        alt: _vm.snackbarImage.file_name
                      }
                    })
                  : _vm._e()
              ]),
              _c("v-flex", { staticClass: "snackbar__content" }, [
                _c("span", { staticClass: "snackbar__content__title" }, [
                  _vm._v(_vm._s(_vm.snackbarTitle))
                ]),
                _vm.snackbarDescription
                  ? _c("div", { staticClass: "snackbar__content__subtitle" }, [
                      _c("span", [_vm._v(_vm._s(_vm.snackbarDescription))])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "snackbar__content__link" }, [
                  _c(
                    "span",
                    {
                      staticClass: "fadedText link",
                      on: {
                        click: function($event) {
                          return _vm.onClickOpenImageDialog(_vm.snackbarImage)
                        }
                      }
                    },
                    [_vm._v("Bekijk")]
                  )
                ])
              ]),
              _c(
                "v-flex",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "snackbar__close",
                      on: {
                        click: function($event) {
                          _vm.isDisplayingSnackbar = false
                        }
                      }
                    },
                    [_vm._v("close")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isDisplayingSettingsDialog
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: true, "max-width": "600" },
              model: {
                value: _vm.isDisplayingSettingsDialog,
                callback: function($$v) {
                  _vm.isDisplayingSettingsDialog = $$v
                },
                expression: "isDisplayingSettingsDialog"
              }
            },
            [
              _c(
                "DefaultDialog",
                {
                  on: {
                    close: function($event) {
                      _vm.isDisplayingSettingsDialog = false
                    }
                  }
                },
                [
                  _c("template", { slot: "header" }, [_vm._v("Instellingen")]),
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.isLoading
                                ? _c("LoaderCard", {
                                    attrs: {
                                      flat: "",
                                      type: "spinner--center",
                                      minHeight: "250px"
                                    }
                                  })
                                : _vm._e(),
                              !_vm.isLoading
                                ? [
                                    _c(
                                      "v-layout",
                                      { attrs: { wrap: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "", "py-0": "" } },
                                          [
                                            _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "primary--text subheading"
                                              },
                                              [_vm._v("Audio")]
                                            )
                                          ]
                                        ),
                                        !_vm.hasPermissionAudio
                                          ? _c(
                                              "v-flex",
                                              {
                                                attrs: { xs12: "", "mb-2": "" }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "warning--text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Geen toegang gegeven om audio apparaten te gebruiken."
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.hasPermissionAudio
                                          ? _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    items:
                                                      _vm.audioInputDevices,
                                                    "return-object": "",
                                                    "hide-datails": "",
                                                    "item-text": "label",
                                                    label: "Microfoon",
                                                    "no-data-text":
                                                      "Geen microfoons"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.selectedAudioInputDevice,
                                                    callback: function($$v) {
                                                      _vm.selectedAudioInputDevice = $$v
                                                    },
                                                    expression:
                                                      "selectedAudioInputDevice"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.hasPermissionAudio
                                          ? _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    items:
                                                      _vm.audioOutputDevices,
                                                    "return-object": "",
                                                    "hide-datails": "",
                                                    "item-text": "label",
                                                    label: "Speaker",
                                                    "no-data-text":
                                                      "Geen speakers"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.selectedAudioOutputDevice,
                                                    callback: function($$v) {
                                                      _vm.selectedAudioOutputDevice = $$v
                                                    },
                                                    expression:
                                                      "selectedAudioOutputDevice"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "", "py-0": "" } },
                                          [
                                            _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "primary--text subheading"
                                              },
                                              [_vm._v("Video")]
                                            )
                                          ]
                                        ),
                                        !_vm.hasPermissionVideo
                                          ? _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "warning--text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Geen toegang gegeven om video apparaten te gebruiken."
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.hasPermissionVideo
                                          ? _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    items:
                                                      _vm.videoInputDevices,
                                                    "return-object": "",
                                                    "hide-datails": "",
                                                    "item-text": "label",
                                                    label: "Camera",
                                                    "no-data-text":
                                                      "Geen cameras"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.selectedVideoInputDevice,
                                                    callback: function($$v) {
                                                      _vm.selectedVideoInputDevice = $$v
                                                    },
                                                    expression:
                                                      "selectedVideoInputDevice"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      !_vm.isLoading
                        ? _c(
                            "v-layout",
                            {
                              attrs: { "justify-end": "", "align-center": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "" } },
                                [
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "fadedText link mr-3",
                                        on: {
                                          click: function($event) {
                                            _vm.isDisplayingSettingsDialog = false
                                          }
                                        }
                                      },
                                      [_vm._v("Annuleren")]
                                    ),
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color: "primary",
                                          small: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.saveSettings()
                                          }
                                        }
                                      },
                                      [_vm._v("Opslaan")]
                                    )
                                  ]
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: true, "max-width": "800" },
          model: {
            value: _vm.isDisplayingAddImageDialog,
            callback: function($$v) {
              _vm.isDisplayingAddImageDialog = $$v
            },
            expression: "isDisplayingAddImageDialog"
          }
        },
        [
          _vm.isDisplayingAddImageDialog
            ? _c("AddImageDialog", {
                attrs: {
                  image: _vm.selectedImage,
                  defaultTarget: _vm.defaultTarget,
                  defaultTargetValue: _vm.defaultTargetValue,
                  meeting: _vm.meeting,
                  isLeader: _vm.isLeader,
                  isDisabled: _vm.isDisabled,
                  damages: _vm.damages
                },
                on: { imageAttached: _vm.onModalImageAttached },
                model: {
                  value: _vm.isDisplayingAddImageDialog,
                  callback: function($$v) {
                    _vm.isDisplayingAddImageDialog = $$v
                  },
                  expression: "isDisplayingAddImageDialog"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: true, "max-width": "800" },
          model: {
            value: _vm.isDisplayingAdditionalRequestDialog,
            callback: function($$v) {
              _vm.isDisplayingAdditionalRequestDialog = $$v
            },
            expression: "isDisplayingAdditionalRequestDialog"
          }
        },
        [
          _vm.isDisplayingAdditionalRequestDialog
            ? _c("AdditionalRequestDialog", {
                attrs: { meeting: _vm.meeting, damages: _vm.damages },
                on: { feedbackSubmitted: _vm.feedbackSubmitted },
                model: {
                  value: _vm.isDisplayingAdditionalRequestDialog,
                  callback: function($$v) {
                    _vm.isDisplayingAdditionalRequestDialog = $$v
                  },
                  expression: "isDisplayingAdditionalRequestDialog"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.isDuplicatingDamage
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: true, "max-width": "500" },
              model: {
                value: _vm.isDuplicatingDamage,
                callback: function($$v) {
                  _vm.isDuplicatingDamage = $$v
                },
                expression: "isDuplicatingDamage"
              }
            },
            [
              _c("DuplicateDamageDialog", {
                attrs: {
                  damage: _vm.activeDamage,
                  visibility: { repairs: false, images: false }
                },
                on: { damageDuplicated: _vm.damageDuplicated },
                model: {
                  value: _vm.isDuplicatingDamage,
                  callback: function($$v) {
                    _vm.isDuplicatingDamage = $$v
                  },
                  expression: "isDuplicatingDamage"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }