var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultDialog",
    { on: { close: _vm.close } },
    [
      _c("template", { slot: "header" }),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.image
            ? _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", "text-center": "" } }, [
                    _c("div", { staticClass: "image__container" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.image.original,
                          alt: _vm.image.file_name
                        }
                      })
                    ])
                  ]),
                  !_vm.isDisabled
                    ? [
                        _vm.isLeader
                          ? _c(
                              "v-layout",
                              { attrs: { wrap: "", "align-center": "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs6: "" } },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.types,
                                        "item-value": "value",
                                        "item-text": "name",
                                        label: "Verplaatsen naar"
                                      },
                                      model: {
                                        value: _vm.selectedType,
                                        callback: function($$v) {
                                          _vm.selectedType = $$v
                                        },
                                        expression: "selectedType"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.hasSelectedDamageType
                                  ? _c(
                                      "v-flex",
                                      { attrs: { xs6: "" } },
                                      [
                                        _c("v-autocomplete", {
                                          staticClass: "with-border",
                                          attrs: {
                                            items: _vm.damages,
                                            "item-text": "name",
                                            "item-value": "uuid",
                                            "hide-details": "",
                                            placeholder: "Zoek een schade",
                                            label: "Schades"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item",
                                                fn: function(data) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getParsedDamageName(
                                                            data.item
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "selection",
                                                fn: function(data) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getParsedDamageName(
                                                            data.item
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2938752924
                                          ),
                                          model: {
                                            value: _vm.selectedDamage,
                                            callback: function($$v) {
                                              _vm.selectedDamage = $$v
                                            },
                                            expression: "selectedDamage"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      !_vm.isDisabled
        ? _c(
            "template",
            { slot: "actions" },
            [
              _vm.isLeader
                ? [
                    !_vm.isLoading
                      ? _c(
                          "v-layout",
                          { attrs: { "justify-end": "", "align-center": "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { shrink: "" } },
                              [
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "fadedText link mr-3",
                                      on: { click: _vm.close }
                                    },
                                    [_vm._v("Annuleren")]
                                  ),
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        color: "primary",
                                        small: "true"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.handleSubmit()
                                        }
                                      }
                                    },
                                    [_vm._v("Opslaan")]
                                  )
                                ]
                              ],
                              2
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }