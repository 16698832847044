var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultDialog",
    { on: { close: _vm.close } },
    [
      _c("template", { slot: "header" }, [_vm._v("Aanvullende gegevens")]),
      _c(
        "template",
        { slot: "content" },
        [
          !_vm.isSucceeded && _vm.isLoading
            ? _c("LoaderCard", {
                attrs: { flat: "", type: "spinner--center", minHeight: "250px" }
              })
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "h4",
                      { staticClass: "content__title font-weight-medium" },
                      [_vm._v("Algemeen")]
                    )
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-textarea", {
                        attrs: { label: "Instructie voor de aanvrager" },
                        model: {
                          value: _vm.payload.additional_request,
                          callback: function($$v) {
                            _vm.$set(_vm.payload, "additional_request", $$v)
                          },
                          expression: "payload.additional_request"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "h4",
                      { staticClass: "content__title font-weight-medium" },
                      [_vm._v("Schades")]
                    )
                  ]),
                  _vm._l(_vm.damages, function(damage, index) {
                    return _c(
                      "v-flex",
                      { key: index, attrs: { xs12: "", "text-center": "" } },
                      [
                        _c(
                          "v-expansion-panel",
                          {
                            staticClass: "damagePanel",
                            attrs: { expand: "" },
                            model: {
                              value: _vm.panels[index],
                              callback: function($$v) {
                                _vm.$set(_vm.panels, index, $$v)
                              },
                              expression: "panels[index]"
                            }
                          },
                          [
                            _c(
                              "v-expansion-panel-content",
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "header" }, slot: "header" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getDamageName(damage, index)
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-card",
                                  { staticClass: "panel" },
                                  [
                                    _c(
                                      "v-card-text",
                                      [
                                        _c("v-textarea", {
                                          attrs: {
                                            label:
                                              "Instructie voor de aanvrager"
                                          },
                                          model: {
                                            value: damage.additional_request,
                                            callback: function($$v) {
                                              _vm.$set(
                                                damage,
                                                "additional_request",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "damage.additional_request"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm.isSucceeded
            ? [
                _c("v-flex", { attrs: { xs12: "", "text-center": "" } }, [
                  _c(
                    "div",
                    { staticClass: "big-info-icon" },
                    [
                      _c("v-icon", { attrs: { color: "#a09b1b" } }, [
                        _vm._v("check_circle")
                      ])
                    ],
                    1
                  )
                ]),
                _c("v-flex", { attrs: { xs12: "", "text-center": "" } }, [
                  _c("span", { staticClass: "fadedText font-weight-bold" }, [
                    _vm._v("Uw uitvraag naar aanvullende gegevens is verwerkt.")
                  ])
                ])
              ]
            : _vm._e()
        ],
        2
      ),
      !_vm.isLoading
        ? _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "v-layout",
                { attrs: { "justify-end": "", "align-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fadedText link mr-3",
                            on: { click: _vm.close }
                          },
                          [_vm._v("Annuleren")]
                        ),
                        _c(
                          "MiButton",
                          {
                            attrs: { color: "primary", small: "true" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.handleSubmit()
                              }
                            }
                          },
                          [_vm._v("aanvragen")]
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }